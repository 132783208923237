import React, { useState, useEffect, useRef } from "react";
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import { Table, Button, Container, Card, Row, Col, Modal, Form } from "react-bootstrap";
//import avatar from "../../images/avatar/avatar-1.jpg";//
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI, showAlert } from '../Utils/utils.js'; // Updated import to use utility functions
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Navigate } from "react-router-dom";

//Data-table
import JSZip from "jszip";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";

// Functional component for RoleList
export default function RoleList() {
    
  // State variables for success messages after CRUD operations
  const [roleAddedSuccessfully, setRoleAddedSuccessfully] = useState(false);
  const [roleEditedSuccessfully, setRoleEditedSuccessfully] = useState(false);
  const [roleDeletedSuccessfully, setRoleDeletedSuccessfully] = useState(false);
  const navigate = useNavigate();
  const tableRef = useRef(null); // Ref for DataTable instance

  // This function runs when the component mounts
  useEffect (() =>{
    // It sets the document title to 'Role | PMRS App'
    document.title = 'Role | PMRS App';
    // This return statement defines a cleanup function
    return ()=> {
      // This function runs when the component unmounts
      document.title = 'PMRS App';
      // It resets the document title back to 'PMRS App'
    };
  },[]);

  // State for role data and input data
  const [roleData, setRoleData] = useState([]);
  const [inputData, setInputData] = useState({
    roleName: '',
    roleDescription: '',
  });

  // State for edited data
  const [EditedData] = useState({
    EditedRoleID: '',
  });

  // Fetch role data from API
  useEffect(() => {

    // Navigate to Login page if no Employee ID Found
    const User_Id = localStorage.getItem('employee_id');
    const isPositionAdmin = localStorage.getItem('Position');
    if (User_Id === null || isPositionAdmin !== 'Admin') {
      navigate('/');
    }

    async function fetchRoleData() {
      try {
        const response = await getFromAPI("role/");
        setRoleData(response);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    }
    fetchRoleData();
  }, []);

  // Effect to display success message after role addition  
  useEffect(() => {
    if (roleAddedSuccessfully) {
      //showAlert("Role added successfully!", 'success');
      toast.success("Role added successfully!", 'success');
      setTimeout(() => {
        setRoleAddedSuccessfully(false);
      }, 2000); // Clear the success message after 3 seconds
    }
  }, [roleAddedSuccessfully]);

  // Effect to display success message after role edition
  useEffect(() => {
    if (roleEditedSuccessfully) {
      //showAlert("Role Edited successfully!", 'success');  
      toast.success("Role Edited successfully!", 'success');
      setTimeout(() => {
        setRoleEditedSuccessfully(false);
      }); // Clear the success message after 3 seconds
    }
  }, [roleEditedSuccessfully]);
  
  // Effect to display success message after role deletion
  useEffect(() => {
    if (roleDeletedSuccessfully) {
      toast.success("Role Deleted successfully!", "success");
      setTimeout(() => {
        setRoleDeletedSuccessfully(false);
      }); // Clear the success message after 3 seconds
    }
  }, [roleDeletedSuccessfully]);

  // Datatable  
  // Initialize DataTable when role data changes
  DataTable.Buttons.jszip(JSZip);
  useEffect(() => {

    // Initialize or update DataTable when milestoneData changes
    if (tableRef.current) {
      // If DataTable has already been initialized, destroy it first
      tableRef.current.clear().destroy();
    }

    // Initialize DataTable
    const dataTableInstance = $('.maindatatable').DataTable({
      dom: 'flrtip',
      language: {
        search: '',
        searchPlaceholder: 'Search...',
        paginate: {
          previous: '«',
          next: '»',
        },
      },
      data: roleData,
      columns: [
        { data: 'role_name' },
        { data: 'role_description' },
        {
          data: null,
          render: function (data, type, row) {
            return `
              <div class="d-flex">
                <button class="btn btn-primary table-btn me-2" data-id="${row.id}" data-action="edit"><i class="bi bi-pencil-square"></i></button>
                <button class="btn btn-danger table-btn" data-id="${row.id}" data-action="delete"><i class="bi bi-trash3"></i></button>
              </div>
            `;
          }
        }
      ]
    });
  
    // Store the DataTable instance in the ref
    tableRef.current = dataTableInstance;
    // Attach event listeners to handle edit and delete actions
    $(document.body).on('click', '.btn.table-btn', function() {
      const action = $(this).data('action');
      const id = $(this).data('id');
      if (action === 'edit') {
        HandleEditActions(id);
      } else if (action === 'delete') {
        handleDeleteRole(id);
      }
    });
  }, [roleData]);

  // Function to handle role addition
  const addRole = async () => {
    try {
      // Check for null or empty values
      if (!inputData.roleName || !inputData.roleDescription) {
        toast.error("Role Name is required.", 'error');
        return;
      } 
      else if (!inputData.roleDescription) {
        toast.error("Role Description is required.", 'error');
        return;
      }

      // If not a duplicate and no null values, proceed with the API call
      const response = await postToAPI("role/", {
        role_name: inputData.roleName,
        role_description: inputData.roleDescription,
      });

      if (response.status) {
        setRoleData(response.data);
        setRoleAddedSuccessfully(true); // Set the state variable to true
      }
    } catch (error) {
      console.error("Error adding role:", error);
    }
  };

  // function for update data in database 
  async function updateDataInDatabase(itemDetails) {
      const updateUrl = `role/${EditedData.EditedRoleID}/`;
        const requestBody = {
          role_name: itemDetails.roleName || null,
          role_description: itemDetails.roleDescription || null,
        };

        try {
          // Make a PUT request to update the data
          const data = await putToAPI(updateUrl, requestBody);
          console.log("Response data:", data);
            if (data.message === 'Item updated successfully') {
              setRoleData(data.data);
              setRoleEditedSuccessfully(true); // Set the state variable to true
              handleClose();
            } else {
              console.error("Unexpected response:", data.message);
            }
        } catch (error) {
          console.error('Error updating item:', error);
        }
    EditedData.EditedRoleID = '';
  }

  // Function to handle role deletion
  const handleDeleteRole = async (itemId) => {
      try {
        // Show confirmation dialog before deleting
        showAlert("Are you sure you want to delete this Role?", "confirm", async (isConfirmed) => {
          if (!isConfirmed) {
            return; // If not confirmed, do nothing
          }

          try {
            const response = await deleteFromAPI(`role/${itemId}/`);

            if (response.message === "Item deleted successfully") {
              setRoleData(response.data);
              setRoleDeletedSuccessfully(true);
            } else {
              console.error("Unexpected response:", response.message);
            }
          } catch (error) {
            console.error("Error deleting role:", error);
          }
        });
      } catch (error) {
        console.error("Error showing delete confirmation:", error);
      }
    EditedData.EditedRoleID = '';
  };
  
  // Function to handle input changes
  const handleInputChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  // Function to handle both add and edit actions
  const handleAddRole = () => {
        // Check if any field in the inputData is empty
        const isAnyFieldEmpty = Object.values(inputData).some(value => value === '');
          if (!inputData.roleName || inputData.roleName.trim() === "") {
            toast.error("Role Name is required!",'error')
            return;
          }
          if (!inputData.roleDescription || inputData.roleDescription.trim() === "") {
            toast.error("Role Description is required!",'error')
            return;
          }

        const isDuplicate = roleData.some((role) => (role.role_name === inputData.roleName) && (role.id !== EditedData.EditedRoleID));
          if (isDuplicate) {
            toast.warn('Role already exists. Please choose a different role name.', 'error');
            return;
          }

          if (isAnyFieldEmpty) {
              toast.error('All fields are required', 'error');
          } else {
              if (EditedData.EditedRoleID === '') {
                  addRole();
              } else {
                if (!inputData.roleName || inputData.roleName.trim() === "") {
                  toast.error("Role Name is required!",'error')
                  return;
                }
                if (!inputData.roleDescription || inputData.roleDescription.trim() === "") {
                  toast.error("Role Description is required!",'error')
                  return;
                } 
                else{
                  updateDataInDatabase(inputData);
              }}
              handleClose();
            }
        };

      // Function to handle edit actions
      const HandleEditActions = (id) => {
        OnEditRole(id);
        handleShow();
      }

    // Code to Update set Edit dialog data
    const OnEditRole = async (itemId) => {
      try {
          const response = await getFromAPI(`role/${itemId}/`);
          const jsonData = JSON.parse(response.data);
            if (response.status) {
              EditedData.EditedRoleID = itemId;
              updateModalContent(jsonData[0].fields);
            } else {
              //showAlert("Some Error Occurred...");
              toast.error("Please fill all fields!", { autoClose: 2000 });
            }
          } catch (error) {
            console.error('Error fetching employee data:', error);
          }
    };

    // Function to Update Modal Contect when click on edit button
    function updateModalContent(itemDetails) {
      setInputData({
        roleName: itemDetails.role_name || "",
        roleDescription: itemDetails.role_description || "",
      });
    }
    const resetInputData = () => {
      setInputData({
        roleName: '',
        roleDescription: '',
      });
    };

  // Function to handle adding a department
  const ADDRole = () => {
    resetInputData(); // Reset input data when opening the modal for adding
    handleShow(); // Show the modal
  };
  
  // State for showing or hiding modal
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    EditedData.EditedRoleID = ''; // Reset EditedRoleID to empty string
  };
  
  const handleShow = () => setShowModal(true);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <PageTitle breadcrumbLast="Role" />
          <Container>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Card.Title className="mb-md-0">Role List</Card.Title>
                      <div>
                        <Button variant="soft-success ms-lg-3" className="btn-icon" onClick={ADDRole}>
                          <i className="bi bi-plus"></i>
                        </Button>
                      </div>
                    </div>
                    <Table responsive bordered className="mb-0 table-nowrap w-100 maindatatable">
                      <thead className="table-light">
                        <tr>
                          <th>Role</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
      <Modal centered show={showModal} onHide={handleClose} dialogClassName="modal-lg" >
        <Modal.Header className="border-0" closeButton>
        <Modal.Title>{EditedData.EditedRoleID ? "Edit Role" : "Add Role"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="text-dark">Role Name</Form.Label>
                  <Form.Control name="roleName" id="formGridroleName" type="text" placeholder="Enter Role name..." onChange={handleInputChange} value={inputData.roleName} />
                </Form.Group>
                <Col lg={12}>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control name="roleDescription" id="textarea" as="textarea"placeholder="Enter Role Description..." rows={3} onChange={handleInputChange} value={inputData.roleDescription} />
                  </Form.Group>
                </Col>

              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAddRole}>
            Ok
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer position="top-left" autoClose={3000} hideProgressBar theme="colored" />
    </>
  );
}