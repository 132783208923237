import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import { getFromAPI } from "../Utils/utils";
import SimpleBar from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { error } from "jquery";

export default function Notification() {
  const [fetchedNotifications, setFetchedNotifications] = useState([]);
  const [activeNotification, setActiveNotification] = useState(null);
  const [milestoneId, setMilestoneId] = useState(null); // State variable for milestone ID\
  const [leaveId, setLeaveId] = useState(null); // State variable for leave ID
  const [teamId, setTeamId] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  // Define a function to get the title and icon based on notification type
  function getTitleAndIcon(notificationType) {
    let title = "";
    let icon = "";

    switch (notificationType) {
      case "project":
        title = "Project Alert";
        icon = "bi bi-files-alt";
        break;
      case "milestone":
        title = "Milestone Alert";
        icon = "bi bi-flag";
        break;
      case "team":
        title = "Team Alert";
        icon = "bi bi-people";
        break;
      case "role":
        title = "Role Alert";
        icon = "bi bi-person-lines-fill";
        break;
      case "department":
        title = "Department Alert";
        icon = "bi bi-diagram-3";
        break;
      case "employee":
        title = "Employee Alert";
        icon = "bi bi-person-circle";
        break;
      case "performance":
        title = "Performance Alert";
        icon = "bi bi-box-arrow-up-right";
        break;
      case "calendar":
        title = "Calendar Alert";
        icon = "bi bi-calendar4-event";
        break;
      case "leave":
        title = "Leave Alert";
        icon = "bi bi-calendar2-check";
        break;
      default:
        title = "Notification";
        icon = "bi bi-bell"; // Default icon
        break;
    }

    return { title, icon };
  }

  // Function to fetch notifications
  async function fetchNotifications() {
    try {
      const data = await getFromAPI("notification/");
      const loggedInUserId = parseInt(localStorage.getItem("employee_id"));
      const filteredData = data.filter(
        (notification) => notification.user === loggedInUserId
      );
      filteredData.sort(
        (a, b) => new Date(b.creation_time) - new Date(a.creation_time)
      );
      setFetchedNotifications(filteredData);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }

  // useEffect to fetch notifications initially
  useEffect(() => {
    fetchNotifications();
    // Poll for new notifications every 5 min
    const interval = setInterval(fetchNotifications, 300000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // Function to fetch milestone data
  async function fetchMilestoneData(milestoneName) {
    try {
      const milestoneData = await getFromAPI('milestone/');
      const matchingMilestone = milestoneData.find(
        (milestone) => milestone.milestone_name === milestoneName
      );
      if (matchingMilestone) {
        setMilestoneId(matchingMilestone.id); // Set the state variable with the milestone ID
      } else {
        setMilestoneId(null); // Clear the state variable if no match is found
      }
    } catch (error) {
      toast.error("Error fetching milestone data:", 'error');
    }
  }

  // Function to fetch milestone data
  async function fetchTeamData(teamName) {
    try {
      const teamdata = await getFromAPI('team/');
      const matchingteam = teamdata.find(
        (milestone) => milestone.team_name === teamName
      );
      if (matchingteam) {
        setTeamId(matchingteam.id); // Set the state variable with the milestone ID
      } else {
        toast.error("No matching Team found",'error');
        setTeamId(null); // Clear the state variable if no match is found
      }
    } catch (error) {
      toast.error("Error fetching Team data:", 'error');
    }
  }

  // Function to fetch leave data
  async function fetchLeaveData(startDate, endDate) {
    try {
      const leaveData = await getFromAPI('leave/');
      const matchingLeave = leaveData.find(
        (leave) => leave.leave_start_date === startDate && leave.leave_end_date === endDate
      );
      if (matchingLeave) {
        setLeaveId(matchingLeave.id);
      } else {
        toast.error("No matching leave found",'error');
        console.log(error.message)
        setLeaveId(null);
      }
    } catch (error) {
      console.error("Error fetching leave data:", 'error');
      console.error(error)
    }
  }

  // Function to fetch project data
  async function fetchProjectData(projectName) {
    try {
      const projectData = await getFromAPI('project/');
      const matchingProject = projectData.find(
        (project) => project.projectname === projectName
      );
      if (matchingProject) {
        setProjectId(matchingProject.id);
      } else {
        toast.error("No matching project found",'error');
        setProjectId(null);
      }
    } catch (error) {
      toast.error("Error fetching project data:", 'error');
    }
  }

  // Function to handle notification click
  const handleNotificationClick = (notification) => {
    let milestoneName = "";
    let teamName = "";
    setActiveNotification(notification.id);

    if (notification.type === "milestone") {
      let match;

      // Match message formats for milestone
      if (match = notification.message.match(/New (.+?) milestone/)) {
        milestoneName = match[1];
      } else if (match = notification.message.match(/(.*?) will end (today|tomorrow)/)) {
        milestoneName = match[1];
      } else if (match = notification.message.match(/The milestone name (.+?) has updated its status/)) {
        milestoneName = match[1];
      }

      if (milestoneName) {
        fetchMilestoneData(milestoneName); // Fetch the milestone data based on the extracted name
      }
    }

    // Match message formats for team
    if (notification.type === "team") {
      let match;

      if (match = notification.message.match(/You have been added to team (.+)/)) {
        teamName = match[1];
      } else if (match = notification.message.match(/(.+?) have been added & (.+?) have been removed from team (.+)/)) {
        teamName = match[3];
      } else if (match = notification.message.match(/(.+?) have been removed from team (.+)/)) {
        teamName = match[2];
      }  else if (match = notification.message.match(/(.+?) have been added to team (.+)/)) {
        teamName = match[2];
      }
      else if (match = notification.message.match(/You have been removed from team (.+)/)) {
        teamName = match[1];
      }

      if (teamName) {
        fetchTeamData(teamName)
      }
    }

    // Match message format for the leave module.
    if (notification.type === "leave") {
      let startDate,endDate;
      let match;
      if (match = notification.message.match(/You had applied for leave from (.+) to (.+)/)) {
        startDate = match[1];
        endDate = match[2];
      } else if (match = notification.message.match(/updated a leave from (.+) to (.+)/)) {
        startDate = match[1];
        endDate = match[2];
      }
      else if(match = notification.message.match(/Your leave is partially approved from (.+) to (.+)/))
      {
        startDate = match[1];
        endDate = match[2];
      }
      else if(match = notification.message.match(/Your leave is approved from (.+) to (.+)/))
      {
        startDate = match[1];
        endDate = match[2];
      }
      else if(match = notification.message.match(/Your leave is partially approved from (.+) to (.+)/))
      {
        startDate = match[1];
        endDate = match[2];
      }
      else if (match = notification.message.match(/(.+?) updated a leave from (.+) to (.+)/)) {
        startDate = match[2];
        endDate = match[3];
      }
      if (match = notification.message.match(/(.+?) applied for leave from (.+) to (.+)/)) {
        startDate = match[2];
        endDate = match[3];
      }
      if (startDate && endDate) {
        fetchLeaveData(startDate, endDate);
      }
    }

    // Match message format for get the project name from projects.
    if (notification.type === "project") {
      let projectName;
      let match;
      if (match = notification.message.match(/You have been assigned a new project (.+) which starts from (.+) to (.+)/)) {
        projectName = match[1];
      } else if (match = notification.message.match(/End date of (.+?) has been updated/)) 
      {
        projectName = match[1];
      } else if (match = notification.message.match(/(.+?) will end today/)) 
      {
        projectName = match[1];
      }
      else if (match = notification.message.match(/(.+?) will end tomorrow/)) 
      {
        projectName = match[1];
      }
      else if(match = notification.message.match(/(.+?) have been added & (.+?) have been removed from project (.+)/))
      {
        projectName = match[3];
      }
      else if(match = notification.message.match(/(.+?) have been added to Team (.+)/))
      {
        projectName = match[2];
      }

      else if(match = notification.message.match(/Start date of (.+?) has been updated to (.+)/))
      {
        projectName = match[1];
      }
      else if(match = notification.message.match(/End date of (.+?) has been updated to (.+)/))
      {
        projectName = match[1];
      }
      else if(match = notification.message.match(/(.+?) have been added to project (.+)/))
      {
        projectName = match[2];
      }
      else if(match = notification.message.match(/(.+?) have been added to project (.+)/))
      {
        projectName = match[2];
      }
      else if(match = notification.message.match(/You have been added to project (.+)/))
      {
        projectName = match[1];
      }
      else if(match = notification.message.match(/(.+?) have been removed from project (.+)/))
      {
        projectName = match[2];
      }
      else if(match = notification.message.match(/You have been removed from project  (.+)/))
      {
        projectName = match[1];
      }

      if (projectName) {
        fetchProjectData(projectName)
      }
    }

    switch (notification.type) {
      // Navigate on the different page according to the notification title.
      
      case "project":
        navigate(`/projects`);
        break;
      case "milestone":
        navigate(`/milestone`);
        break;
      case "team":
        navigate(`/team`);
        break;
      case "role":
        navigate(`/role`);
        break;
      case "department":
        navigate(`/department`);
        break;
      case "employee":
        navigate(`/employee`);
        break;
      case "performance":
        navigate(`/performance`);
        break;
      case "calendar":
        navigate(`/calendar`);
        break;
      case "leave":
        navigate(`/leave`);
        break;
      default:
        navigate(`/notifications`);
        break;
    }
  };

  useEffect(() => {
    if (teamId) {
      navigate(`/team/${teamId}`);
      setTeamId(null);
    }
  }, [teamId, navigate]);

  useEffect(() => {
    if (milestoneId) {
      navigate(`/milestone/${milestoneId}`);
      setMilestoneId(null);
    }
  }, [milestoneId, navigate]);

  useEffect(() => {
    if (leaveId) {
      navigate(`/leave/${leaveId}`);
      setLeaveId(null);
    }
  }, [leaveId, navigate]);

  useEffect(() => {
    if (projectId) {
      navigate(`/projects/${projectId}`);
      setProjectId(null);
    }
  }, [projectId, navigate]);

  useEffect(() => {
    if (milestoneId) {
      navigate(`/milestone/${milestoneId}`);
    }
  }, [milestoneId, navigate]);

  // Render notifications here
  return (
    <>
      <SimpleBar className="custom-select">
        {/* Render fetched notifications */}
        {fetchedNotifications.map((notification) => {
          const { title, icon } = getTitleAndIcon(notification.type);
          return (
            <React.Fragment>
              <Dropdown.Item
                key={notification.id}
                as="button"
                onClick={() => handleNotificationClick(notification)}
                className={`noti-item custom-dropdown-item ${notification.id === activeNotification ? 'active' : ''}`}
                >
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className={icon} style={{ color: '#29314F' }}></i>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <div className="d-flex justify-content-between c-mb-2">
                      <h6 className="noti-title mb-0"style={{ color: '#29314F' }}>{title}</h6>
                      <p className="noti-time mb-0 fs-12">
                        {notification.creation_time
                          ? (() => {
                              const timeDifference =
                                new Date() - new Date(notification.creation_time);
                              const minutes = Math.floor(
                                timeDifference / (1000 * 60)
                              );
                              if (minutes < 60) {
                                return `${minutes} minutes ago`;
                              } else if (minutes < 60 * 24) {
                                const hours = Math.floor(minutes / 60);
                                return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`;
                              } else {
                                const days = Math.floor(minutes / (60 * 24));
                                return `${days} ${days > 1 ? 'days' : 'day'} ago`;
                              }
                            })()
                          : null}
                      </p>
                    </div>
                    <p className="noti-subtitle fs-13 mb-0">{notification.message}</p>
                  </div>
                </div>
              </Dropdown.Item>
            </React.Fragment>
          );
        })}
      </SimpleBar>
      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar
        theme="colored"
      />
    </>
  );
}
