import React, { useState, useEffect, useCallback, useRef } from "react";
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import { Container, Form, Card, Row, Col, Button, Table, Modal } from 'react-bootstrap';
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI, showAlert, sendNotification } from '../Utils/utils.js';
import DatePicker from 'react-datepicker';
import { useDateFormat } from "../../context/DateFormatContext.js";
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from "react-toastify";
import TopBar from "../layout/TopBar.js";
import { useParams } from 'react-router-dom';

//Data-table
import $ from "jquery";
import { forEach } from "jszip";

export default function Leave() {

    // This function runs when the component mounts
    useEffect(() => {
        // It sets the document title to 'Leave | PMRS App'
        document.title = 'Leave | PMRS App';
        // This return statement defines a cleanup function
        return () => {
            // This function runs when the component unmounts
            document.title = 'PMRS App';
            // It resets the document title back to 'PMRS App'
        };
    }, []);

    const [leaveListData, setleaveData] = useState([]);
    const [Employee, setEmployee] = useState([]);
    const { selectedFormat } = useDateFormat();
    const [EditedleaveID, setEditedleaveID] = useState('');
    const [settingData, setsetting] = useState([]);
    const [reciveremail, setreciveremail] = useState([]);
    const [useremail, setuseremail] = useState([]);
    const [userposition, setuserposition] = useState([]);
    const [reporting_employee, setreporting_employee] = useState();
    const [FinalReporting_to_Email, setFinalReporting_to_email] = useState();
    const [Supervisor_name, setSupervisor_name] = useState();
    const tableRef = useRef(null);
    // fetch employee name from employee Headerbar
    const { szfullName = localStorage.getItem('First_name') + ' ' + localStorage.getItem('Last_name') } = TopBar();
    // State for showing or hiding modal
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleShowModal1 = () => setShowModal1(true)
    const [reportingToId, setReportingToId] = useState(null); // State to store reportingToId
    const { id } = useParams();

    const [inputData, setInputData] = useState({
        leave_employee_name: szfullName,
        leave_type: '',
        leave_start_date: '',
        leave_end_date: '',
        leave_part_selection: '',
        leave_rejection_reason: '',
        leave_description: '',
        leave_total_days: '',
    });

    useEffect(() => {
        async function fetchEmployee() {
            const data = await getFromAPI('employee/');
            setEmployee(data);
            
            const loggedInUserId = parseInt(localStorage.getItem("employee_id"));
            const filteredData = data.filter(
                (employee) => employee.employee_id === loggedInUserId
            );
            
            if (filteredData.length > 0) {
                const currentEmployee = filteredData[0];
                const reportingEmployee = currentEmployee.reporting_to;
                const senderEmployee = currentEmployee.email;
                const senderEmployeeposition = currentEmployee.position;                
                setuserposition(senderEmployeeposition);
                setuseremail(senderEmployee);
                
                const reportingPersonNames = reportingEmployee.split(" ");
                const reportingPersonName = reportingPersonNames.join(" ");
                setreporting_employee(reportingPersonName);
                
                const firstName = reportingPersonNames[0];
                const lastName = reportingPersonNames.slice(1).join(" ");
                
                const reportingPerson = data.find(person =>
                    person.first_name === firstName && person.last_name === lastName
                );
                
                if (reportingPerson) {
                    const reportingEmail = reportingPerson.email;
                    setreciveremail(reportingEmail);
                    setSupervisor_name(reportingPerson.reporting_to);
                    
                    if (reportingPerson.reporting_to) {
                        const supervisor = data.find(person =>
                            person.first_name === reportingPerson.reporting_to.split(" ")[0] &&
                            person.last_name === reportingPerson.reporting_to.split(" ")[1]
                        );
                        
                        if (supervisor) {
                            const newFinalReporting_to = supervisor.email;
                            setFinalReporting_to_email(newFinalReporting_to);
                        }
                    }
                }
                
                // Find the employee_id of reporting_to_management
                const reportingToManagement = currentEmployee.reporting_to_management;
                const reportingToManagementNames = reportingToManagement.split(" ");
                const reportingToManagementFirstName = reportingToManagementNames[0];
                const reportingToManagementLastName = reportingToManagementNames.slice(1).join(" ");
                
                const reportingToManagementPerson = data.find(person =>
                    person.first_name === reportingToManagementFirstName && person.last_name === reportingToManagementLastName
                );
                
                if (reportingToManagementPerson) {
                    const reportingToManagementId = reportingToManagementPerson.employee_id;
                    // Do something with the reportingToManagementId if needed
                }
                
                // Find the employee_id of reporting_to
                const reportingToPerson = data.find(person =>
                    person.first_name === firstName && person.last_name === lastName
                );
                
                if (reportingToPerson) {
                    setReportingToId(reportingToPerson.employee_id);
                    // Do something with the reportingToId if needed
                }
            }
        }
        fetchEmployee();
    }, []);
    
    const [totalLeave, setTotalLeave] = useState(0);
    const [casualLeave, setCasualLeave] = useState(0);
    const [paidLeave, setPaidLeave] = useState(0);
    const [sickLeave, setSickLeave] = useState(0);

    useEffect(() => {
        fetchData1();
    }, []);

    const fetchData1 = async () => {
        try {
            // Fetch data from API using getFromApi function
            var leaveData = await getFromAPI(`leave/employee_Id/${localStorage.getItem("employee_id")}/`);
            leaveData = JSON.parse(leaveData.data);
            const totalLeaveData = await getFromAPI('setting/');

            // Calculate total leave count in hours for different leave types
            const totalLeaveHours = totalLeaveData.reduce((acc, setting) => acc + parseInt(setting.annualLeave) * 8, 0);
            const totalPaidLeaveHours = totalLeaveData.reduce((acc, setting) => acc + parseInt(setting.paidLeave) * 8, 0);
            const totalSickLeaveHours = totalLeaveData.reduce((acc, setting) => acc + parseInt(setting.sickLeave) * 8, 0);
            const totalCasualLeaveHours = totalLeaveData.reduce((acc, setting) => acc + parseInt(setting.casualLeave) * 8, 0);

            // Group leave data by employee_id
            const leaveDataByEmployee = leaveData.reduce((acc, leave) => {
                if (!acc[leave.employee_Id]) {
                    acc[leave.employee_Id] = [];
                }
                acc[leave.employee_Id].push(leave);
                return acc;
            }, {});

            // Calculate booked leave duration for each employee
            let approvedLeaveHours = 0;
            let casualLeaveHours = 0;
            let paidLeaveHours = 0;
            let sickLeaveHours = 0;

            for (const [employeeId, employeeLeaves] of Object.entries(leaveDataByEmployee)) {
                employeeLeaves.forEach(leave => {
                    if (leave.fields.leave_status === 'Approved') {
                        const { days, hours } = parseDaysAndHours(leave.fields.leave_total_days);
                        approvedLeaveHours += days * 8 + hours;

                        // Increment hours for each leave type
                        if (leave.fields.leave_type === 'Casual Leave') {
                            casualLeaveHours += days * 8 + hours;
                        } else if (leave.fields.leave_type === 'Paid Leave') {
                            paidLeaveHours += days * 8 + hours;
                        } else if (leave.fields.leave_type === 'Sick Leave') {
                            sickLeaveHours += days * 8 + hours;
                        }
                    }
                });
            }

            // Calculate remaining leave duration in hours for each type of leave
            const remainingCasualLeaveHours = totalCasualLeaveHours - casualLeaveHours;
            const remainingPaidLeaveHours = totalPaidLeaveHours - paidLeaveHours;
            const remainingSickLeaveHours = totalSickLeaveHours - sickLeaveHours;

            // Convert remaining leave duration to days and hours for each type of leave
            const remainingCasualLeaveDays = Math.floor(remainingCasualLeaveHours / 8);
            const remainingCasualLeaveHoursPart = remainingCasualLeaveHours % 8;

            const remainingPaidLeaveDays = Math.floor(remainingPaidLeaveHours / 8);
            const remainingPaidLeaveHoursPart = remainingPaidLeaveHours % 8;

            const remainingSickLeaveDays = Math.floor(remainingSickLeaveHours / 8);
            const remainingSickLeaveHoursPart = remainingSickLeaveHours % 8;

            const availableLeavesDays = Math.floor((remainingCasualLeaveHours + remainingPaidLeaveHours + remainingSickLeaveHours) / 8);
            const availableLeavesHoursPart = (remainingCasualLeaveHours + remainingPaidLeaveHours + remainingSickLeaveHours) % 8;

            //Math.abs() for converting negative number into positive since we don't have to show negative hours.
            setCasualLeave(`${remainingCasualLeaveDays}.${Math.abs(remainingCasualLeaveHoursPart)}`);
            setPaidLeave(`${remainingPaidLeaveDays}.${Math.abs(remainingPaidLeaveHoursPart)}`);
            setSickLeave(`${remainingSickLeaveDays}.${Math.abs(remainingSickLeaveHoursPart)}`);
            // Update state with calculated remaining leave
            setTotalLeave(`${availableLeavesDays}.${Math.abs(availableLeavesHoursPart)}`);


        } catch (error) {
            toast.error('Error fetching leave data:', error);
            // Handle error fetching data
        }
    };

    const parseDaysAndHours = (duration) => {
        const regex = /(\d+) days (\d+) hours/;
        const match = duration.match(regex);

        if (match) {
            const days = parseInt(match[1]);
            const hours = parseInt(match[2]);
            return { days, hours };
        } else {
            return { days: 0, hours: 0 }; // Default to 0 days and 0 hours if format doesn't match
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);
    // Fetch data from API on component mount for employees
        const  fetchData = async () => {
            try {
                // Fetch leave data
                const [response,response2] = await Promise.all([
                    getFromAPI("leave/"),
                    getFromAPI('setting/'),
                  ]);

                
                setsetting(response2);

                // Get the employee ID from local storage
                const employeeId = localStorage.getItem('employee_id');
                const employee_position = localStorage.getItem('Position');
                
        
                // Filter leave data based on the employee ID
                const leaveForUser = (employee_position === 'Admin' ? response : response.filter(leave => leave.employee_Id == employeeId));
                if(id)
                    {// Filter milestones by user's team IDs
                      const leaveData1 = await getFromAPI(`leave/${id}/`);
                      const data = JSON.parse(leaveData1.data);
                      setleaveData([data[0].fields]); 
                    }          
                else{
                      setleaveData(leaveForUser)
                    }
                // Set the filtered leave data
                
            } catch (error) {
                // Handle errors
                toast.error("Error fetching data:", error);
            }
        }


    // Initialize DataTable on leaveListData change
    useEffect(() => {
        // Initialize or update DataTable when leaveListData changes
        if (tableRef.current) {
            // If DataTable has already been initialized, destroy it first
            tableRef.current.clear().destroy();
        }

        // Initialize DataTable
        const dataTableInstance = $('.maindatatable').DataTable({
            dom: 'flrtip',
            language: {
                search: '',
                searchPlaceholder: 'Search...',
                paginate: {
                    previous: '«',
                    next: '»',
                },
            },
            data: leaveListData, // Add leaveListData directly to the DataTable
            columns: [
                { data: 'leave_employee_name' },
                { data: 'leave_type' },
                {
                    data: null,
                    render: function (data, type, row) {
                        const returnvalue = formatDate(row.leave_start_date) + ' To ' + formatDate(row.leave_end_date);
                        return (
                            `<span>${returnvalue}</span>`
                        );
                    }
                },
                { data: 'leave_total_days' },
                { data: 'leave_status' },
                {
                    data: null,
                    render: function (data, type, row) {
                        if (row.leave_status === 'Approved' || row.leave_status === 'Rejected' || row.leave_status === 'Partially Approved') {
                            // If leave status is 'Approved', hide delete button
                            return '';
                        }
                        return `
                            <div class="d-flex">
                                <button class="btn btn-primary table-btn me-2" data-id='${JSON.stringify(row).replace(/'/g, "&#39;")}' data-action="edit"><i class="bi bi-pencil-square"></i></button>
                                <button class="btn btn-danger table-btn" data-id='${JSON.stringify(row).replace(/'/g, "&#39;")}' data-action="delete"><i class="bi bi-trash3"></i></button>
                            </div>
                            `;
                    }
                }
            ]
        });
        // Store the DataTable instance in the ref
        tableRef.current = dataTableInstance;
        // Attach event listeners to handle edit and delete actions
        $(document.body).on('click', '.btn.table-btn', function () {
            const action = $(this).data('action');
            const rowData = $(this).data('id');
            if (action === 'edit') {
                HandleEditActions(rowData);
            } else if (action === 'delete') {
                handleDeleteLeave(rowData.id);
            }
        });
    }, [leaveListData]);

    //function for the deleting the leave
    const handleDeleteLeave = async (leaveId) => {
        try {
            showAlert('Are you sure you want to delete this Leave?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    deleteLeave(leaveId);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            toast.error('Error deleting Leave', 'error');
        }
    };

    //function for the deleting the Leave form the database
    const deleteLeave = async (leaveId) => {
        try {
            let response;
            if(id){
             response = await deleteFromAPI(`leave/${id}/`);
            }
            else{
                response = await deleteFromAPI(`leave/${leaveId}/`);
            }
            if (response.status) {
                setleaveData((prevData) => prevData.filter((leave) => leave.id !== leaveId));
                toast.success('Leave deleted successfully!', 'success');
            } else {
                toast.error('Error deleting leave', 'error');
            }
        } catch (error) {
            toast.error('Error deleting leave', 'error');
        }
    };

    const OnAddingLeave = async (e) => {
        try {
            const formattedStartDate = convertToYYYYMMDD(inputData.leave_start_date);
            const formattedEndDate = convertToYYYYMMDD(inputData.leave_end_date);
            const duration = calculateHalfDayDuration(inputData.leave_start_date, inputData.leave_end_date, inputData.leave_part_selection);
            const User_Id = localStorage.getItem('employee_id');
            
            // Fetch existing leaves for the user
            const existingLeaves = await getFromAPI("leave/");

            const employeeIds = existingLeaves.map(leave => leave.employee_Id);
            
            // Filter leaves based on User_Id
            const userLeaves = existingLeaves.filter(leave => leave.employee_Id == User_Id);
            
            // Additional debug: Log each comparison
            existingLeaves.forEach(leave => {
                console.log(`Comparing ${leave.employee_Id} with ${User_Id}:`, leave.employee_Id == User_Id);
            });
            
            
            // Check for duplicate leave dates
            const isDuplicate = userLeaves.some(leave => {
                
                return (formattedStartDate <= leave.leave_start_date && formattedEndDate >= leave.leave_end_date);
            });

            if (isDuplicate) {
                toast.error('Duplicate entry. You have already applied for leave within these dates.', 'error');
                return; // Exit the function if a duplicate is found
            }
    
            toast.success("Leave added successfully!", "success");

            // Proceed with API request if no duplicate is found
            const data = await postToAPI("leave/", {
                leave_employee_name: inputData.leave_employee_name || szfullName,
                leave_type: inputData.leave_type,
                leave_start_date: formattedStartDate,
                leave_end_date: formattedEndDate,
                leave_part_selection: inputData.leave_part_selection,
                leave_status: 'Pending',
                leave_rejection_reason: inputData.leave_rejection_reason,
                leave_description: inputData.leave_description,
                leave_total_days: duration,
                employee_Id: User_Id,
                leaver_email: useremail,
                leave_report_to_email: reciveremail,
                leave_report_to_name: reporting_employee,
                FinalReporting_to: FinalReporting_to_Email,
                final_reportTo_name: Supervisor_name,
            });
    

            if (data.status) {
                const response = await getFromAPI("leave/");
                const employee_position = localStorage.getItem('Position');
                const leaveForUser = (employee_position === 'Admin' ? response : response.filter(leave => leave.employee_Id == User_Id));
                setleaveData(leaveForUser);
                handleCloseModal();
                const creation_time = new Date().toISOString();
                const type = "leave";
                const user = User_Id;
                const teamData = await getFromAPI("team/");
    
                const otherTeamMembers = [];
    
                teamData.forEach((team) => {
                    if (team.employee_select.includes(User_Id.toString())) {
                        const employeeIds = team.employee_select.split(",");
                        employeeIds.forEach((employeeId) => {
                            const trimmedId = parseInt(employeeId.trim(), 10);
                            const loggedInUserId = parseInt(localStorage.getItem("employee_id"), 10);
                            if (trimmedId !== User_Id && trimmedId !== loggedInUserId && !otherTeamMembers.includes(trimmedId)) {
                                otherTeamMembers.push(trimmedId);
                            }
                        });
                    }
                });
    
                const messageToUser = `You had applied for leave from ${formattedStartDate} to ${formattedEndDate}`;
                await sendNotification('notification/',
                    type,
                    messageToUser,
                    creation_time,
                    user, false
                );
    
                const messageToReportPerson = `${inputData.leave_employee_name} applied for leave from ${formattedStartDate} to ${formattedEndDate}`;
                await sendNotification('notification/',
                    type,
                    messageToReportPerson,
                    creation_time,
                    reportingToId, false
                );
    
                otherTeamMembers.forEach(async (employeeId) => {
                    const messageToOtherUser = `${inputData.leave_employee_name} applied for leave from ${formattedStartDate} to ${formattedEndDate}`;
                    await sendNotification('notification/',
                        type,
                        messageToOtherUser,
                        creation_time,
                        employeeId, false
                    );
                });
            } else {
                if (data.error && data.error.includes('duplicate')) {
                    toast.error('Duplicate entry. Please provide a different leave date.', 'error');
                } else {
                    showAlert('Some Error Occurred...', 'error');
                }
            }
            setEditedleaveID('');
        } catch (error) {
            toast.error('Error adding leave date:', error);
            toast.warn('Error adding leave date', 'error');
        }
    };
    

    // for updating values to database
    
    const updateDataInDatabase = useCallback(async (itemDetails) => {
        let updateUrl
        if(id)
        {
         updateUrl = `leave/${id}/`;
        }
        else
        {
        updateUrl = `leave/${EditedleaveID}/`;
        }
        if (!itemDetails.leave_employee_name || !itemDetails.leave_type || !itemDetails.leave_start_date || !itemDetails.leave_end_date || !itemDetails.leave_part_selection || !itemDetails.leave_description) {
            toast.error('All Fields Are Required', 'error');
            return;
        }
        const formattedStartDate = convertToYYYYMMDD(itemDetails.leave_start_date);
        const formattedEndDate = convertToYYYYMMDD(itemDetails.leave_end_date);
        const duration = calculateHalfDayDuration(itemDetails.leave_start_date, itemDetails.leave_end_date, itemDetails.leave_part_selection);
        const User_Id = localStorage.getItem('employee_id');
        const requestBody = {
            leave_employee_name: itemDetails.leave_employee_name || null,
            leave_type: itemDetails.leave_type || null,
            leave_start_date: formattedStartDate || null,
            leave_end_date: formattedEndDate || null,
            leave_part_selection: itemDetails.leave_part_selection || null,
            leave_status: itemDetails.leave_status || null,
            leave_rejection_reason: itemDetails.leave_rejection_reason || null,
            leave_description: itemDetails.leave_description || null,
            leave_total_days: duration || null,
        };

        try {
            // Make a PUT request to update the data
            const data = await putToAPI(updateUrl, requestBody);
            if (data.message === "Item updated successfully") {
                toast.success("Leave Edited successfully!", "success");
                fetchData();
                const response = await getFromAPI("leave/");
                const employee_position = localStorage.getItem('Position');
                // Filter leave data based on the employee ID
                const leaveForUser = (employee_position === 'Admin' ? response : response.filter(leave => leave.employee_Id == User_Id));
                // Set the filtered leave data
                setleaveData(leaveForUser);
                const creation_time = new Date().toISOString();
                const type = "leave";
                const user = User_Id;
                //Notification for leave
                const teamData = await getFromAPI("team/");

                // Define an array to store other team members' IDs
                const otherTeamMembers = [];

                // Iterate through the teamData array to find teams in which the user is a member
                // Iterate through the teamData array to find teams in which the user is a member
                teamData.forEach((team) => {
                    // Check if the user is a member of the current team
                    if (team.employee_select.includes(User_Id.toString())) {
                        // Split the employee_select string into individual employee IDs
                        const employeeIds = team.employee_select.split(",");

                        // Iterate through each employee ID in the team
                        employeeIds.forEach((employeeId) => {
                            // Trim and parse the employee ID
                            const trimmedId = parseInt(employeeId.trim(), 10);

                            // Retrieve the logged-in user's ID
                            const loggedInUserId = parseInt(localStorage.getItem("employee_id"), 10);

                            // Ensure the employee ID is not the current user's ID, not the logged-in user's ID, and not already in the otherTeamMembers array
                            if (
                                trimmedId !== User_Id &&
                                trimmedId !== loggedInUserId &&
                                !otherTeamMembers.includes(trimmedId)
                            ) {
                                // Add the employee ID to the otherTeamMembers array
                                otherTeamMembers.push(trimmedId);
                            }
                        });
                    }
                });              
                
                // Send notifications
                // First notification to the user who applied for leave
                const messageToUser = `You had updated a leave from ${formattedStartDate} to ${formattedEndDate}`;
                await sendNotification('notification/',
                    type,
                    messageToUser,
                    creation_time,
                    user, false
                );

                const messageToReportPerson =  `${inputData.leave_employee_name} had updated a leave from ${formattedStartDate} to ${formattedEndDate}`;
                await sendNotification('notification/',
                    type,
                    messageToReportPerson,
                    creation_time,
                    reportingToId, false
                );
                // Second notification to other team members
                otherTeamMembers.forEach(async (employeeId) => {
                    const messageToOtherUser = `${itemDetails.leave_employee_name} has updated a leave from ${formattedStartDate} to ${formattedEndDate}`;
                    await sendNotification('notification/',
                        type,
                        messageToOtherUser,
                        creation_time,
                        employeeId, false
                    );
                });
            } else {
                toast.error('Unexpected response:', data.message);
            }
        } catch (error) {
            // Handle network or other errors
            toast.error('Error updating leave:', error);
            toast.warn('Error updating leave', 'error');
        }
        setEditedleaveID('');
    }, [EditedleaveID, toast, setleaveData]);

    // function for the data setup and updates the state inputData
    const updateModalContent = (leave) => {
        const duration = calculateHalfDayDuration(leave.leave_start_date, leave.leave_end_date, leave.leave_part_selection);
        const leavePartSelection = leave.leave_part_selection.replace(/'/g, '"');
        const temp = JSON.parse(leavePartSelection);
        temp.forEach((value, index) => {
            $(`#leave_part_selection${index}`).val(value);
        });

        setInputData({
            leave_employee_name: leave.leave_employee_name,
            leave_type: leave.leave_type,
            leave_start_date: leave.leave_start_date,
            leave_end_date: leave.leave_end_date,
            leave_part_selection: temp,
            leave_rejection_reason: leave.leave_rejection_reason,
            leave_description: leave.leave_description,
            leave_total_days: duration,
        });
        fetchData1();
    };

    // Function to format the date based on the selected format
    const formatDate = (dateString) => {
        // If the dateString is empty or null, return an empty string
        if (!dateString) return '';

        // Create a new Date object with the parts in 'MM-DD-YYYY' format
        const date = new Date(dateString);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            toast.error(`Invalid date: ${dateString}`);
            return ''; // Return an empty string if the date is invalid
        }

        // Get the date components
        const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero if necessary
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Pad with leading zero if necessary
        const year = date.getFullYear();

        const options = { year: 'numeric', month: '2-digit', day: '2-digit' }; // Define the default format

        // Custom formats based on the selected format
        switch (selectedFormat) {
            case 'YYYY-MM-DD':
                // Use custom format for 'YYYY-MM-DD'
                return year + "-" + month + "-" + day;
            case 'DD-MM-YYYY':
                // Use custom format for 'DD-MM-YYYY'
                return day + "-" + month + "-" + year;
            case 'MM-DD-YYYY':
                // Use custom format for 'MM-DD-YYYY'
                return month + "-" + day + "-" + year;
            // Add more cases for additional formats as needed
            default:
                // Default to the standard format
                return date.toLocaleDateString('en-GB', options);
        }
    };

    // function for the calulting the total days 
    const calculateHalfDayDuration = (start, end, partSelections) => {
        const oneDay = 8; // 8 hours count as one day
        const halfDayHours = 4; // 4 hours for half-day

        const startDate = new Date(start);
        const endDate = new Date(end);

        if (!start || !end) {
            return ''; // Return empty string if start or end date is not provided
        }

        // If part selections array is provided, calculate the total duration accordingly
        if (Array.isArray(partSelections)) {
            let selectedHalfDays = 0;
            partSelections.forEach(partSelection => {
                if (partSelection === "First Half" || partSelection === "Second Half") {
                    selectedHalfDays++;
                }
            });

            // Calculate total duration considering selected half-days
            const daysDifference = Math.ceil((endDate - startDate + 1) / (1000 * 60 * 60 * 24));
            let totalDuration = daysDifference * oneDay - selectedHalfDays * halfDayHours;

            // Calculate total days and remaining hours
            const totalDays = Math.floor(totalDuration / oneDay);
            const remainingHours = totalDuration % oneDay;

            return `${totalDays} days ${remainingHours} hours`;
        } else if (partSelections === "First Half" || partSelections === "Second Half") {
            // If part selection is "First Half" or "Second Half", return half-day duration
            return `0 days ${halfDayHours} hours`;
        } else {
            // Calculate total duration normally
            const daysDifference = Math.ceil((endDate - startDate + 1) / (1000 * 60 * 60 * 24));
            const totalDuration = daysDifference * oneDay;
            const totalDays = Math.floor(totalDuration / oneDay);
            const remainingHours = totalDuration % oneDay;
            return `${totalDays} days ${remainingHours} hours`;
        }
    };

    // Function to convert any date format to yyyy-mm-dd format
    const convertToYYYYMMDD = useCallback((dateString) => {
        // Create a new Date object from the input string
        const date = new Date(dateString);

        // Check if the input string is a valid date
        if (isNaN(date.getTime())) {
            return ''; // Return an empty string if the date is invalid
        }

        // Get the components of the date (year, month, day)
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if necessary
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if necessary

        // Return the date in yyyy-mm-dd format
        return `${year}-${month}-${day}`;
    }, []);

    //function for the handling change date format    
    const handleChangeDate = useCallback((date, fieldName) => {
        setInputData((prevData) => ({
            ...prevData,
            [fieldName]: date,
        }));
    }, []);


    // function for the handling data change
    const handleChange = useCallback((e) => {
        const { name, value } = e.target;

        if (name === "leave_status" && (value !== "Rejected" || value !== "Cancelled")) {
            setInputData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else {
            setInputData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
        setInputData({
            leave_employee_name: szfullName,
            leave_type: '',
            leave_start_date: '',
            leave_end_date: '',
            leave_part_selection: '',
            leave_rejection_reason: '',
            leave_description: '',
            leave_total_days: '',
        })
        setShowModal(false);
        setEditedleaveID(''); // Reset editedTeamID
    };
    const handleCloseModal1 = () => {
        setInputData({
            leave_employee_name: szfullName,
            leave_type: '',
            leave_start_date: '',
            leave_end_date: '',
            leave_part_selection: '',
            leave_rejection_reason: '',
            leave_description: '',
            leave_total_days: '',
        })
        setShowModal1(false);
    };

    // Handle both Add and Update actions
    const handleBothActions = useCallback(() => {

        if (convertToYYYYMMDD(inputData.leave_start_date) > convertToYYYYMMDD(inputData.leave_end_date)) {
            toast.warn("End Date Should be greater than Start date", 'error')
            return;
        }
        // Check if other required fields are filled
        if (!inputData.leave_employee_name || !inputData.leave_type || !inputData.leave_start_date || !inputData.leave_end_date || !inputData.leave_description || inputData.leave_part_selection.includes('') || inputData.leave_part_selection.includes(null)) {
            toast.error('All fields are required', 'error');
            return;
        }
        if (generateDateRange().length !== (inputData.leave_part_selection).length) {
            toast.error('All fields are required', 'error')
            return;
        }

        if ((inputData.leave_status === 'Rejected' || inputData.leave_status === 'Cancelled') && !inputData.leave_rejection_reason) {
            if (inputData.leave_status === 'Rejected') {
                toast.error("Reason for rejection is required", 'error')
            }
            if (inputData.leave_status === 'Cancelled') {
                toast.error("Reason for cancelation is required", 'error')
            }
            return;
        }
        if (EditedleaveID === '') {
            OnAddingLeave();
            handleCloseModal();
            fetchData1();
        } else {
            // For editing an existing leave
            if (convertToYYYYMMDD(inputData.leave_start_date) > convertToYYYYMMDD(inputData.leave_end_date)) {
                toast.warn("End Date Should be greater than Start date", 'error')
                return;
            }
            if (!inputData.leave_employee_name || !inputData.leave_type || !inputData.leave_start_date || !inputData.leave_end_date || !inputData.leave_part_selection || !inputData.leave_description || inputData.leave_part_selection.includes('') || inputData.leave_part_selection.includes(null)) {
                toast.error('All fields are required', 'error');
                return; // Add this return statement to exit early if leave part selection is incomplete
            }
            if (generateDateRange().length !== (inputData.leave_part_selection).length) {
                toast.error('All fields are required', 'error')
                return;
            }

            if ((inputData.leave_status === 'Rejected' || inputData.leave_status === 'Cancelled') && !inputData.leave_rejection_reason) {
                if (inputData.leave_status === 'Rejected') {
                    toast.error("Reason for rejection is required", 'error')
                }
                if (inputData.leave_status === 'Cancelled') {
                    toast.error("Reason for cancelation is required", 'error')
                }
                return;
            }
            else {
                updateDataInDatabase(inputData);
                handleCloseModal();
                fetchData1();
            }
        }
    }, [inputData, leaveListData, EditedleaveID]);

    // For dropdown list  // Event handler for dropdown list
    const handleSelection = useCallback((e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value });
    }, [inputData]);

    // For handling Edit action
    const HandleEditActions = useCallback((leave) => {
        setEditedleaveID(leave.id);
        updateModalContent(leave);
        handleShowModal();
    }, []);

    // For handling Edit action
    const HandleViewActions = (leave) => {
        setEditedleaveID(leave.id);
        updateModalContent(leave);
        handleShowModal1();
    };

    // Function to generate an array of dates between start and end dates
    const generateDateRange = useCallback(() => {
        const startDate = new Date(inputData.leave_start_date);
        const endDate = new Date(inputData.leave_end_date);
        const dates = [];
        let currentDate = new Date(startDate); // Create a new Date object

        while (currentDate <= endDate) {
            dates.push(new Date(currentDate)); // Push a new Date object with the same value
            currentDate.setDate(currentDate.getDate() + 1); // Increment date by one day
        }
        if (dates.length < inputData.leave_part_selection.length) {
            (inputData.leave_part_selection).splice(dates.length, inputData.leave_part_selection.length);
        }
        return dates;
    }, [inputData]);

    // Updated handlePartSelectionChange function
    const handlePartSelectionChange = useCallback((e, index) => {
        const { value } = e.target;
        setInputData((prevData) => {
            const updatedLeavePartSelection = [...prevData.leave_part_selection];
            updatedLeavePartSelection[index] = value;

            // Ensure the length of leave_part_selection matches the number of dates
            const numDates = generateDateRange().length;
            while (updatedLeavePartSelection.length < numDates) {
                updatedLeavePartSelection.push(null); // Add null values for additional dates
            }

            return {
                ...prevData,
                leave_part_selection: updatedLeavePartSelection,
            };
        });
    }, [inputData]);

    return (
        <>
            <div className='page-wrapper'>
                <div className='page-content'>
                    <PageTitle breadcrumbLast='Leave' />
                    <Container>
                        <Row>
                            <Col className='mb-4' md={6} xl={3}>
                                <Card className='mb-0 h-100 text-bg-success bg-opacity-50'>
                                    <Card.Body>
                                        <div className='d-flex justify-content-center'>
                                            <Card.Title className="text-white">Annual Leave</Card.Title>
                                        </div>
                                        <div className='ms-3'>
                                            <h3 className='mb-0 me-4 fs-4 d-flex justify-content-center'>{totalLeave}/{settingData && settingData.map(setting => setting.annualLeave)}</h3>
                                            <p className='mb-0 me-4 d-flex justify-content-center'>Available</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className='mb-4' md={6} xl={3}>
                                <Card className='mb-0 h-100 text-bg-info bg-opacity-50'>
                                    <Card.Body>
                                        <div className='d-flex justify-content-center'>
                                            <Card.Title className="text-white">Casual Leave</Card.Title>
                                        </div>
                                        <div className='ms-3'>
                                            <h3 className='mb-0 me-4 fs-4 d-flex justify-content-center'>{casualLeave}/{settingData && settingData.map(setting => setting.casualLeave)}</h3>
                                            <p className='mb-0 me-4 d-flex justify-content-center'>Available</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col className='mb-4' md={6} xl={3}>
                                <Card className='mb-0 h-100 text-bg-warning bg-opacity-50'>
                                    <Card.Body>
                                        <div className='d-flex justify-content-center'>
                                            <Card.Title className="text-white">Paid Leave</Card.Title>
                                        </div>
                                        <div className='ms-3'>
                                            <h3 className='mb-0 me-4 fs-4 d-flex justify-content-center'>{paidLeave}/{settingData && settingData.map(setting => setting.paidLeave)}</h3>
                                            <p className='mb-0 me-4 d-flex justify-content-center'>Available</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col className='mb-4' md={6} xl={3}>
                                <Card className='mb-0 h-100 text-bg-danger bg-opacity-50'>
                                    <Card.Body>
                                        <div className='d-flex justify-content-center'>
                                            <Card.Title className="text-white">Sick Leave</Card.Title>
                                        </div>
                                        <div className='ms-3'>
                                            <h3 className='mb-0 me-3 fs-4 d-flex justify-content-center'>{sickLeave}/{settingData && settingData.map(setting => setting.sickLeave)}</h3>
                                            <p className='mb-0 me-3 d-flex justify-content-center'>Available</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <Card.Title>Leave Table</Card.Title>
                                            <div>
                                                <Button variant="soft-success ms-lg-3" className="btn-icon" onClick={handleShowModal}>
                                                    <i className="bi bi-plus"></i>
                                                </Button>
                                            </div>
                                        </div>
                                        <Table
                                            responsive
                                            bordered
                                            className='mb-0 w-100 table-nowrap maindatatable'
                                        >
                                            <thead className='table-light'>
                                                <tr>
                                                    <th>Employee Name</th>
                                                    <th>Leave type</th>
                                                    <th>Leave Period </th>
                                                    <th>Days/Hours Taken</th>
                                                    <th>Leave Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>

            {/* modal */}
            <Modal centered show={showModal} onHide={handleCloseModal} dialogClassName="modal-lg">
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title>{EditedleaveID ? 'Edit Leave' : 'Add Leave'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col>
                                <Form>
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group className="custom-fr-group custom-form-input">
                                                <Form.Label>Employee Select </Form.Label>
                                                <Form.Select defaultValue="szfullname" name="leave_employee_name" id="leave_employee_name" value={inputData.leave_employee_name} onChange={handleChange} disabled>
                                                    <option value="" >Select Employee Name</option>
                                                    {Employee.map((item) => (
                                                        <option key={item.id} value={`${item.first_name} ${item.last_name}`}>
                                                            {`${item.first_name} ${item.last_name}`}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>Leave Type<span className="text-danger">*</span></Form.Label>
                                                <Form.Select defaultValue="Select" name="leave_type" id="leave_type" value={inputData.leave_type} onChange={handleSelection}>
                                                    <option value="">Select Leave Type</option>
                                                    <option>Casual Leave</option>
                                                    <option>Sick Leave</option>
                                                    <option>Paid Leave</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>Leave Description<span className="text-danger">*</span></Form.Label>
                                                <Form.Control name="leave_description" id="leave_description" as="textarea" placeholder="Enter Leave Description..." rows={3} onChange={handleChange} value={inputData.leave_description} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4} sm={12}>
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>Start Date<span className="text-danger">*</span></Form.Label>
                                                <div className="input-icon-content custom-form-input">
                                                    <i className={`bi bi-calendar input-icon`} onClick={() => document.getElementById('start_date_picker').click()}></i>
                                                </div>
                                                <DatePicker
                                                    id="start_date_picker"
                                                    selected={inputData.leave_start_date ? new Date(inputData.leave_start_date) : null}
                                                    onChange={(date) => handleChangeDate(date, "leave_start_date")}
                                                    placeholderText="Select start date"
                                                    dateFormat={formatDate(inputData.leave_start_date)}
                                                    showPopperArrow={false}
                                                    autoComplete="off"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4} sm={12}>
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>End Date<span className="text-danger">*</span></Form.Label>
                                                <div className="input-icon-content">
                                                    <i className={`bi bi-calendar input-icon`} onClick={() => document.getElementById('end_date_picker').click()}></i>
                                                </div>
                                                <DatePicker
                                                    id="end_date_picker"
                                                    selected={inputData.leave_end_date ? new Date(inputData.leave_end_date) : null}
                                                    onChange={(date) => handleChangeDate(date, "leave_end_date")}
                                                    placeholderText="Select end date"
                                                    dateFormat={formatDate(inputData.leave_end_date)}
                                                    showPopperArrow={false}
                                                    autoComplete="off"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4} sm={12} >
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>Total Days</Form.Label>
                                                <Form.Control
                                                    type="days"
                                                    name="days"
                                                    id="days"
                                                    placeholder="Total Days"
                                                    value={calculateHalfDayDuration(
                                                        convertToYYYYMMDD(inputData.leave_start_date),
                                                        convertToYYYYMMDD(inputData.leave_end_date),
                                                        inputData.leave_part_selection
                                                    )}
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="date-loop-section mb-3">
                                            <div className="border px-3 pt-3 rounded-2">
                                                {generateDateRange().map((date, index) => (
                                                    <div key={index} className="date-container">
                                                        <div className="date-details">
                                                            <div>
                                                                <Row className="mb-3 align-items-center">
                                                                    <Col md={4} >
                                                                        {formatDate(date)}
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <Form.Select
                                                                            onChange={(e) => handlePartSelectionChange(e, index)}
                                                                        >
                                                                            <option value="">Select</option>
                                                                            <option value="Full Day" selected={inputData.leave_part_selection[index] === "Full Day" ? true : false} >Full Day</option>
                                                                            <option value="First Half" selected={inputData.leave_part_selection[index] === "First Half" ? true : false}>First Half</option>
                                                                            <option value="Second Half" selected={inputData.leave_part_selection[index] === "Second Half" ? true : false}>Second Half</option>
                                                                        </Form.Select>
                                                                    </Col>
                                                                    <Col md={4} className="text-center">
                                                                        <Form.Control type="email" placeholder="Enter email" title=""
                                                                            value={calculateHalfDayDuration(
                                                                                convertToYYYYMMDD(date),
                                                                                convertToYYYYMMDD(date),
                                                                                inputData.leave_part_selection[index]
                                                                            )}
                                                                            readOnly />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleBothActions}>
                        Ok
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
        </>
    );
}