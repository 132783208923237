import React, { useState, useEffect } from 'react'
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import { Container, Row, Col, Card, Modal  } from "react-bootstrap";
import { getFromAPI } from '../Utils/utils.js'; // Import utility functions
import user from "../../images/user1.png";
import { useNavigate, Navigate } from "react-router-dom";
import { useDateFormat } from "../../context/DateFormatContext.js";

export default function EmployeeProfile() {


  // This function runs when the component mounts
  useEffect (() =>{
    // It sets the document title to 'Employee Profile | PMRS App'
    document.title = 'Employee Profile | PMRS App';
      // This return statement defines a cleanup function
      return ()=> {
      // This function runs when the component unmounts
      document.title = 'PMRS App';
      // It resets the document title back to 'PMRS App'
    };
  },[]);


  // State for modal visibility
  const [showModal, setShowModal] = useState(false);
  const [showModalForPan, setShowModalForPan] = useState(false);
  const [showModalForAadhar, setShowModalForAadhar] = useState(false);
  const [showModalForPassPortPic, setShowModalForPassPortPic] = useState(false);
  const navigate = useNavigate();
  const { selectedFormat } = useDateFormat();

  // Function to handle click on file thumbnail
  // const handleFileClick = (id) => {
  //   setShowModal(true);
  // };


  // function for the logged-in employee data in to the Container
  const [EmployeeeData, SetEmployeeeData] = useState([]);
  useEffect(() => {

    // Navigate to Login page if no Employee ID Found
    const User_Id = localStorage.getItem('employee_id');
    if (User_Id === null) {
      navigate('/');
    }

    const fetchEmployeeeData = async () => {
      try {
        const User_Id = localStorage.getItem('employee_id');
        const response = await getFromAPI(`employee/employee_id/${User_Id}/`);
        SetEmployeeeData(JSON.parse(response.data)[0].fields);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchEmployeeeData(); 
  }, []);

   // Function to format the date based on the selected format
   const formatDate = (dateString) => {
    // If the dateString is empty or null, return an empty string
    if (!dateString) return '';

    // Create a new Date object with the parts in 'MM-DD-YYYY' format
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return ''; // Return an empty string if the date is invalid
    }

    // Get the date components
    const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero if necessary
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Pad with leading zero if necessary
    const year = date.getFullYear();

    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }; // Define the default format

    // Custom formats based on the selected format
    switch (selectedFormat) {
        case 'YYYY-MM-DD':
            // Use custom format for 'YYYY-MM-DD'
            return year + "-" + month + "-" + day;
        case 'DD-MM-YYYY':
            // Use custom format for 'DD-MM-YYYY'
            return day + "-" + month + "-" + year;
        case 'MM-DD-YYYY':
            // Use custom format for 'MM-DD-YYYY'
            return month + "-" + day + "-" + year;
        // Add more cases for additional formats as needed
        default:
            // Default to the standard format
            return date.toLocaleDateString('en-GB', options);
    }
};


  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <PageTitle breadcrumbLast="Employee Profile" />

          <Container>
            <Row>
              {/* first card */}
              <Col>
                <Card>
                  <Card.Body>
                    <div className="d-sm-flex d-block align-items-center text-sm-start text-center">
                      {EmployeeeData.profile_picture ? (
                        <img className="rounded-circle img-thumbnail avatar-img-xxl" src={EmployeeeData.profile_picture} alt="default" />
                      ) : (
                        <img className="rounded-circle img-thumbnail avatar-img-xxl" src={user} alt="default" />
                      )}
                      <div className="ms-sm-4 ms-0 mt-sm-0 mt-3">
                        <h2 > {EmployeeeData.first_name} {EmployeeeData.last_name}</h2>
                        <h5 className="text-muted"> {EmployeeeData.position}</h5>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {/* second card */}
            <Row>
              <Col>
                <Card>
                  <Card.Body className="pb-0">
                    <Card.Title>Basic Info</Card.Title>
                    <Row>
                      <Col md={6} xl={4}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">First Name</p>
                          <h5 className="text-dark mb-0">{EmployeeeData.first_name}</h5>
                        </div>
                      </Col>

                      <Col md={6} xl={4}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">Last Name</p>
                          <h5 className="text-dark mb-0">{EmployeeeData.last_name}</h5>
                        </div>
                      </Col>

                      <Col md={6} xl={4}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">Employee ID</p>
                          <h5 className="text-dark mb-0">{EmployeeeData.employee_id}</h5>
                        </div>
                      </Col>

                      <Col md={6} xl={4}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">Email</p>
                          <h5 className="text-dark mb-0">
                            {EmployeeeData.email}
                          </h5>
                        </div>
                      </Col>
                      <Col md={6} xl={4}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">Position</p>
                          <h5 className="text-dark mb-0">{EmployeeeData.position}</h5>
                        </div>
                      </Col>
                      <Col md={6} xl={4}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">Contact No</p>
                          <h5 className="fs-20 text-dark">{EmployeeeData.contact_no}</h5>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* Third card */}
            <Row>
              <Col>
                <Card>
                  <Card.Body className="pb-0">
                    <Card.Title>Work Info</Card.Title>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">Department</p>
                          <h5 className="text-dark mb-0">{EmployeeeData.department}</h5>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">Reporting To</p>
                          <h5 className="text-dark mb-0">{EmployeeeData.reporting_to}</h5>
                        </div>
                      </Col>

                      <Col md={6} xl={4}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">Date of Join</p>
                          <h5 className="text-dark mb-0">{formatDate(EmployeeeData.join_date)}</h5>
                        </div>
                      </Col>
                      <Col md={6} xl={4}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">
                            Employee Status
                          </p>
                          <h5 className="text-dark mb-0">{EmployeeeData.employee_status}</h5>
                        </div>
                      </Col>
                      <Col md={6} xl={4}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">Employee Type</p>
                          <h5 className="text-dark mb-0">{EmployeeeData.employee_type}</h5>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* Third Card */}
            <Row>
              <Col>
                <Card>
                  <Card.Body className="pb-0">
                    <Card.Title>Personal Details</Card.Title>
                    <Row>
                      {/* <Col lg={12}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">Address</p>
                          <h5 className="text-dark mb-0">3410 Diamond CoveProvidence, RI 02905</h5>
                        </div>
                      </Col> */}

                      <Col md={6} xl={4}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">City</p>
                          <h5 className="text-dark mb-0">{EmployeeeData.city}</h5>
                        </div>
                      </Col>

                      <Col md={6} xl={4}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">State</p>
                          <h5 className="text-dark mb-0">{EmployeeeData.state}</h5>
                        </div>
                      </Col>
                      <Col md={6} xl={4}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">Country</p>
                          <h5 className="text-dark mb-0">{EmployeeeData.country}</h5>
                        </div>
                      </Col>

                      <Col md={6} xl={3}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">
                            Date of Birth
                          </p>
                          <h5 className="text-dark mb-0">{formatDate(EmployeeeData.dob)}</h5>
                        </div>
                      </Col>
                      <Col md={6} xl={3}>
                        <div className="d-flex flex-column mb-4">
                          <p className="text-muted fs-18 mb-1">Gender</p>
                          <h5 className="text-dark mb-0">{EmployeeeData.gender}</h5>
                        </div>
                      </Col>
                      <Col md={6} xl={3}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">
                            Marital Status
                          </p>
                          <h5 className="fs-20 text-dark">{EmployeeeData.marital_status}</h5>
                        </div>
                      </Col>
                      <Col md={6} xl={3}>
                        <div className="mb-4">
                          <p className="text-muted fs-18 mb-1">Hobbies</p>
                          <h5 className="fs-20 text-dark">
                            {EmployeeeData.hobbies}
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {/* Display files section */}
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <h5>Files</h5>
                    <Row>
                      <Col sm={6} md={4} lg={3}>
                        <div className="file-thumbnail">
                          <i class="bi bi-files" onClick={() => setShowModal(true)}>
                            <span> Show File</span>
                          </i>
                        </div>
                      </Col> 
                      <Col sm={6} md={4} lg={3}>
                        <div className="file-thumbnail"> 
                          <i class="bi bi-files" onClick={() => setShowModalForPan(true)}>
                            <span> Show Pan Card File</span>
                          </i>
                          </div>
                      </Col>  
                      <Col sm={6} md={4} lg={3}>
                        <div className="file-thumbnail"> 
                          <i class="bi bi-files" onClick={() =>  setShowModalForAadhar(true)}>
                            <span> Show Aadhar Card File</span>
                          </i>
                          </div>
                      </Col>
                      <Col sm={6} md={4} lg={3}>
                        <div className="file-thumbnail"> 
                          <i class="bi bi-files" onClick={() =>  setShowModalForPassPortPic(true)}>
                            <span> Show Passport size Picture File</span>
                          </i>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>

      {/* Modal to display PDF content */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" >
        <Modal.Header closeButton>
          <Modal.Title>PDF Viewer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Use an iframe to display the PDF content */}
          {EmployeeeData.files && EmployeeeData.files !== '\n' ? (
            // If a PDF file exists and it's not a newline character, display it in an iframe
            <iframe src={EmployeeeData.files} id="files" title="PDF Viewer" width="100%" height="500px" />
          ) : (
            // If no PDF file exists or it's a newline character, display an error message
            <p>No PDF file is uploaded yet.</p>
          )}
        </Modal.Body>
      </Modal>
      <Modal show={showModalForPan} onHide={() => setShowModalForPan(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>PDF Viewer for Pan Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Use an iframe to display the PDF content */}
          {EmployeeeData.pan_files && EmployeeeData.pan_files !== '\n' ? (
            // If a PDF file exists and it's not a newline character, display it in an iframe
            <iframe src={EmployeeeData.pan_files} id="pan_files" title="PDF Viewer" width="100%" height="500px" />
          ) : (
            // If no PDF file exists or it's a newline character, display an error message
            <p>Pan Card PDF file is Not uploaded yet.</p>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showModalForAadhar} onHide={() => setShowModalForAadhar(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>PDF Viewer for Aadhar Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Use an iframe to display the PDF content */}
          {EmployeeeData.aadhar_files && EmployeeeData.aadhar_files !== '\n' ? (
            // If a PDF file exists and it's not a newline character, display it in an iframe
            <iframe src={EmployeeeData.aadhar_files} id="aadhar_files" title="PDF Viewer" width="100%" height="500px" />
          ) : (
            // If no PDF file exists or it's a newline character, display an error message
            <p>Aadhar card PDF file is Not uploaded yet.</p>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showModalForPassPortPic} onHide={() => setShowModalForPassPortPic(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>PDF Viewer For Passport size Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Use an iframe to display the PDF content */}
          {EmployeeeData.passport_pic && EmployeeeData.passport_pic !== '\n' ? (
            // If a PDF file exists and it's not a newline character, display it in an iframe
            <iframe src={EmployeeeData.passport_pic} id="passport_pic" title="PDF Viewer" width="100%" height="500px" />
          ) : (
            // If no PDF file exists or it's a newline character, display an error message
            <p>Passport size Picture PDF file is Not uploaded yet.</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
