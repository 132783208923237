import React, { useState, useEffect, useCallback } from "react";
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import { Card, Container, Row, Col, Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDateFormat } from "../../context/DateFormatContext.js";
import 'react-datepicker/dist/react-datepicker.css';
import { getFromAPI , postToAPI , putToAPI , deleteFromAPI,sendNotification } from "../Utils/utils";
import { toast, ToastContainer } from "react-toastify";
//Data-table
import JSZip from "jszip";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $, { event } from "jquery";
import DataTable from "datatables.net-dt";
import TopBar from "../layout/TopBar.js";
import { useNavigate, Navigate ,useLocation } from "react-router-dom";

import LeaveApprovalData from './LeaveApprovalData.json'

export default function LeaveApproval() {

    DataTable.Buttons.jszip(JSZip);
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable(".maindatatable")) {
            $(".maindatatable").DataTable({
                dom: "flrtip",
                language: {
                    search: "",
                    searchPlaceholder: "Search Leave approval...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });
        }
    }, []);

    useEffect(() => {
        async function fetchData() {

            const [response1,response2] = await Promise.all([
                getFromAPI("employee/"),
                getFromAPI('setting/')
              ]);
            
            setEmployee(response1);
            setsetting(response2);
        }
        fetchData();
    }, []);

    const [settingData, setsetting] = useState([]);
    const { selectedFormat } = useDateFormat();
    const [leaveidData, setleaveidData] = useState([]);
    const [bookedLeave, setBookedLeave] = useState(0);
    const [availableLeave, setAvailableLeave] = useState(0);
    const [employeeListData, setEmployeeListData] = useState([]);
    const navigate = useNavigate();
    const [previous_token , setPrevious_token] =useState([]);
    const [Employee, setEmployee] = useState([]);
    const [token , set_Token] =useState([]);
    const [showButtons, setShowButtons] = useState(true);
    const { szfullName = localStorage.getItem('First_name') + ' ' + localStorage.getItem('Last_name') } = TopBar();
    const [inputData, setInputData] = useState({
        leave_employee_name: szfullName,
        leave_type: '',
        leave_start_date: '',
        leave_end_date: '',
        leave_part_selection: '',
        leave_rejection_reason: '',
        leave_description: '',
        leave_total_days: '',
    });

    const location = useLocation();
    const UrlsParams = new URLSearchParams(location.search);
    const [reportingToId, setReportingToId] = useState(null); // State to store reportingToId


    // Retrieve props from URL
    const Leaveid = UrlsParams.get('id');


    useEffect(() => {
        // Get the current URL
        const url = window.location.href;

        // Parse the URL and extract parameters
        const params = new URLSearchParams(url);

        // Get the value of the 'token' parameter
        const token = params.get('token');
        
        // If token is null or undefined, do not proceed further
        if (!token) {
            return;
        }

        // Set the token value to the state variable
        set_Token(token);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Check if both tokens exist and are the same
        if (token && previous_token && token === previous_token) {
            setShowButtons(true);
        } else {
            setShowButtons(false);
        }
    }, [token, previous_token]);

    useEffect(() => {
        async function fetchEmployee() {
            const data = await getFromAPI('employee/');
            
            const loggedInUserId = parseInt(localStorage.getItem("employee_id"));
            const filteredData = data.filter(
                (employee) => employee.employee_id === loggedInUserId
            );
            
            if (filteredData.length > 0) {
                const currentEmployee = filteredData[0];
                const reportingEmployee = currentEmployee.reporting_to;
                
                const reportingPersonNames = reportingEmployee.split(" ");
                const firstName = reportingPersonNames[0];
                const lastName = reportingPersonNames.slice(1).join(" ");

                
                // Find the employee_id of reporting_to
                const reportingToPerson = data.find(person =>
                    person.first_name === firstName && person.last_name === lastName
                );
                
                if (reportingToPerson) {
                    setReportingToId(reportingToPerson.employee_id);
                    // Do something with the reportingToId if needed
                }
            }
        }
        fetchEmployee();
    }, []);
    
     console.log(reportingToId)
    // Fetch data from project
    useEffect(() => {

        // Navigate to Login page if no Employee ID Found
        const User_Id = localStorage.getItem('employee_id');
        if (User_Id === null) {
            navigate('/');
        }

        async function fetchData() {
            try {
                // Fetch employee data
                const response = Leaveid !== null ?  await getFromAPI(`leave/id/${Leaveid}/`): await getFromAPI("leave/") ;
                if (response.status && response.data && response.data.length > 0) {
                    const leaveidData = JSON.parse(response.data); // Parse the data field as JSON
                    setleaveidData(leaveidData[0]);
                    if (Array.isArray(leaveidData) && leaveidData.length > 0) {
                        const leaveFields = leaveidData[0].fields; // Get the fields object    
                        // Update state with leave data
                        setInputData({
                            leave_employee_name: leaveFields.leave_employee_name,
                            employee_Id : leaveFields.employee_Id,
                            leave_type: leaveFields.leave_type,
                            leave_start_date: leaveFields.leave_start_date,
                            leave_end_date: leaveFields.leave_end_date,
                            leave_part_selection: leaveFields.leave_part_selection,
                            leave_rejection_reason: leaveFields.leave_rejection_reason,
                            leave_description: leaveFields.leave_description,
                            leave_total_days: leaveFields.leave_total_days,
                            leave_tokens : leaveFields.leave_tokens,
                            leave_status : leaveFields.leave_status
                        });
                        setPrevious_token(leaveFields.leave_tokens)
                    } else {
                        toast.error("No leave data found in the response:", response);
                    }
                } else {
                    toast.error("No leave data found in the response:", response);
                }
            } catch (error) {
                toast.error("Error fetching data:", error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchEmails() {
            const data = await getFromAPI('employee/');
            console.log(data)
            const updatedEmployeeListData = data.map((employee) => ({
                    value: employee.employee_id,
                    label: `${employee.first_name} ${employee.last_name} - ${employee.role}`,
                }));
            setEmployeeListData(updatedEmployeeListData);
            const loggedInUserId = parseInt(localStorage.getItem("employee_id"));
            const filteredData = data.filter(
                (employee) => employee.employee_id === loggedInUserId
            );

            // Check if filteredData contains any elements
            if (filteredData.length > 0) {
                const reportingEmployee = filteredData[0].reporting_to;

                // Split reporting person's name into first and last name
                const reportingPersonNames = reportingEmployee.split(" ");
                const firstName = reportingPersonNames[0];
                const lastName = reportingPersonNames.slice(1).join(" "); // in case there are multiple last names

                // Find the reporting person in the data
                const reportingPerson = data.find(person =>
                    person.first_name === firstName && person.last_name === lastName
                );

                let reportingEmail = "";
                let reportingReportEmail = "";
                let hrEmails = [];

                if (reportingPerson) {
                    // Get the email of the reporting person
                    reportingEmail = reportingPerson.email;

                    // Check if reporting person is reporting to someone else
                    if (reportingPerson.reporting_to) {
                        // Find the supervisor using first name and last name
                        const supervisor = data.find(person =>
                            person.first_name === reportingPerson.reporting_to.split(" ")[0] &&
                            person.last_name === reportingPerson.reporting_to.split(" ")[1]
                        );

                        if (supervisor) {
                            // Get the email of the supervisor
                            reportingReportEmail = supervisor.email;
                        }
                    }
                }
                // Find emails of employees whose role is "hr", "Hr", or "HR"
                hrEmails = data.filter(employee =>
                    employee.role.toLowerCase() === "hr"
                ).map(hrEmployee => hrEmployee.email);

            }
        }

        fetchEmails();
    }, []);

    useEffect(() => {
        fetchData(leaveidData);
    }, [leaveidData]);

    const fetchData = async (leaveidData) => {


        if (leaveidData === undefined || leaveidData.length === 0) return;
        try {
            // Fetch data from API using getFromApi function
            var leaveData = await getFromAPI(`leave/employee_Id/${leaveidData.fields.employee_Id}/`);
            leaveData = JSON.parse(leaveData.data);
            const totalLeaveData = await getFromAPI('setting/');
            // Calculate total leave count in hours
            const totalLeaveHours = totalLeaveData.reduce((acc, setting) => acc + parseInt(setting.annualLeave) * 8, 0);

            // Group leave data by employee_id
            const leaveDataByEmployee = leaveData.reduce((acc, leave) => {
                if (!acc[leave.employee_Id]) {
                    acc[leave.employee_Id] = [];
                }
                acc[leave.employee_Id].push(leave);
                return acc;
            }, {});

            // Calculate booked leave duration for each employee
            let approvedLeaveHours = 0; // Initialize approved leave hours
            for (const [employeeId, employeeLeaves] of Object.entries(leaveDataByEmployee)) {
                let bookedLeaveDays = 0;
                let bookedLeaveHours = 0;
                    employeeLeaves.forEach(leave => {
                        if (leave.fields.leave_status == 'Pending' || leave.fields.leave_status == 'Partially Approved') {
                            const { days, hours } = parseDaysAndHours(leave.fields.leave_total_days);
                            bookedLeaveHours += days * 8 + hours;
                        }
                        else if (leave.fields.leave_status == 'Approved') { 
                            // Increment approved leave hours
                            const { days, hours } = parseDaysAndHours(leave.fields.leave_total_days);
                            approvedLeaveHours += days * 8 + hours;
                        }
                    });

                    // Convert booked leave duration to days and hours
                    // Adjusting for carry forward hours
                    
                    bookedLeaveDays += Math.floor(bookedLeaveHours / 8);
                    bookedLeaveHours %= 8;

                    // Set the booked leave state for the current employee
                    setBookedLeave(`${bookedLeaveDays}.${bookedLeaveHours}`);

                    // Calculate available leave 
                    const availableLeaveHours = totalLeaveHours - approvedLeaveHours;

                    // Convert available leave duration to days and hours
                    const availableLeaveDays = Math.floor(availableLeaveHours / 8);
                    const availableLeaveHoursPart = availableLeaveHours % 8;

                    // Update state with calculated available leave
                    setAvailableLeave(`${availableLeaveDays}.${Math.abs(availableLeaveHoursPart)}`);

                }

        } catch (error) {
            toast.error('Error fetching leave data:', error);
            // Handle error fetching data
        }
    };
                
    const parseDaysAndHours = (duration) => {
        const regex = /(\d+) days (\d+) hours/;
        const match = duration.match(regex);
    
        if (match) {
            const days = parseInt(match[1]);
            const hours = parseInt(match[2]);
            return { days, hours };
        } else {
            return { days: 0, hours: 0 }; // Default to 0 days and 0 hours if format doesn't match
        }
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
      
        if (name === "projectstatus" && value !== "On Hold") {
          setInputData((prevData) => ({
            ...prevData,
            reason_for_hold: "", // Clear the reason_for_hold field when changing project status from "On Hold"
            [name]: value,
          }));
        } else {
          setInputData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      };

    // Function to format the date based on the selected format
    const formatDate = (dateString) => {
        // If the dateString is empty or null, return an empty string
        if (!dateString) return '';

        // Create a new Date object with the parts in 'MM-DD-YYYY' format
        const date = new Date(dateString);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            toast.error(`Invalid date: ${dateString}`);
            return ''; // Return an empty string if the date is invalid
        }

        // Get the date components
        const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero if necessary
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Pad with leading zero if necessary
        const year = date.getFullYear();

        const options = { year: 'numeric', month: '2-digit', day: '2-digit' }; // Define the default format

        // Custom formats based on the selected format
        switch (selectedFormat) {
            case 'YYYY-MM-DD':
                // Use custom format for 'YYYY-MM-DD'
                return year + "-" + month + "-" + day;
            case 'DD-MM-YYYY':
                // Use custom format for 'DD-MM-YYYY'
                return day + "-" + month + "-" + year;
            case 'MM-DD-YYYY':
                // Use custom format for 'MM-DD-YYYY'
                return month + "-" + day + "-" + year;
            // Add more cases for additional formats as needed
            default:
                // Default to the standard format
                return date.toLocaleDateString('en-GB', options);
        }
    };

    const [errors, setErrors] = useState({
        leave_rejection_reason:"",
    });
    // Handle both Add and Update actions
    const handleBothActions = async (event, id) => {
        
        const validationErrors = {};
        const buttonText = event.target.textContent.trim()
        // Validation for first and last name
       if (buttonText === "Rejected" && (!inputData.leave_rejection_reason || inputData.leave_rejection_reason.trim() === "") ){
           validationErrors.leave_rejection_reason = "Reason for leave rejection is required";
       }
        // Update errors state
       setErrors(validationErrors);

        // If there are validation errors, show alert and do not proceed with editing
       if (Object.keys(validationErrors).length !== 0) {
           toast.error("Please fill in all required fields correctly.", "error");
           return;
        }

        try {
            const Existing_userData = await getFromAPI("leave/") ;
            
            for (const leave of Existing_userData) {
                const Existing_id = Leaveid;
                if (leave.id == Existing_id){
                    const buttonText = event.target.textContent.trim()
                    if (id === Existing_id && buttonText === "Approved" && leave.leave_status === 'Pending') { 
                        toast.success('Leave status updated from Pending to Partially Approved successfully!');
                        setShowButtons(false);
                        await putToAPI(`leave/${Existing_id}/`, { leave_status: 'Partially Approved' });

                         // Send notification for leave approval
                         const type = "leave";
                         const creation_time = new Date().toISOString();
                         const messageToUser = `Your leave is partially approved from ${leave.leave_start_date} to ${leave.leave_end_date}`;
                         await sendNotification('notification/',
                            type,
                            messageToUser,
                            creation_time,
                            leave.employee_Id, false
                        );

                        const messageToThirdReport = `${leave.leave_employee_name} leave is partially approved from ${leave.leave_start_date} to ${leave.leave_end_date}`;
                        await sendNotification('notification/',
                           type,
                           messageToThirdReport,
                           creation_time,
                           reportingToId, false
                       );


                        const storing_token = await getFromAPI(`leave/id/${Existing_id}/`);
                        const parsedData = (JSON.parse(storing_token.data)[0].fields);
                        setPrevious_token(parsedData.leave_tokens)
                        return;
                    } else if (leave.leave_status === 'Partially Approved' && id === Existing_id && buttonText === "Approved") {
                        toast.success('Leave status updated from Partially Approved to Approved successfully!', 'success');
                        setShowButtons(false);
                        await putToAPI(`leave/${Existing_id}/`, { leave_status: 'Approved' });
                         // Send notification for leave approval
                                                 // Send notification for leave approval
                         const type = "leave";
                         const messageToUser = `Your leave is approved from ${leave.leave_start_date} to ${leave.leave_end_date}`;
                         const creation_time = new Date().toISOString();
                         await sendNotification('notification/',
                            type,
                            messageToUser,
                            creation_time,
                            leave.employee_Id, false
                        );
                        const storing_token = await getFromAPI(`leave/id/${Existing_id}/`);
                        const parsedData = (JSON.parse(storing_token.data)[0].fields);
                        setPrevious_token(parsedData.leave_tokens)
                        return;
                    } else if ((leave.leave_status === 'Partially Approved' || leave.leave_status === 'Pending') && id === Existing_id && buttonText === "Rejected") {
                        toast.success('Leave status updated to rejected successfully!', 'success');
                        setShowButtons(false);
                        await putToAPI(`leave/${Existing_id}/`, { leave_status: 'Rejected' , leave_rejection_reason: inputData.leave_rejection_reason});
                         // Send notification for leave rejection
                        // Send notification for leave approval
                         const type = "leave";
                         const messageToUser = `Your leave is Rejected from ${leave.leave_start_date} to ${leave.leave_end_date}`;
                         const creation_time = new Date().toISOString();
                         await sendNotification('notification/',
                            type,
                            messageToUser,
                            creation_time,
                            leave.employee_Id, false
                        );
                        const storing_token = await getFromAPI(`leave/id/${Existing_id}/`);
                        const parsedData = (JSON.parse(storing_token.data)[0].fields);
                        setPrevious_token(parsedData.leave_tokens)
                        return;
                    } else {
                        
                    }
                }
                
            }
        } catch (error) {
            toast.error("An error occurred:", error);
            toast.error('An error occurred while processing the request', 'error');
        }
    };

    //function for the handling change date format    
    const handleChangeDate = (date, fieldName) => {
        setInputData((prevData) => ({
            ...prevData,
            [fieldName]: date, // Update the state with the selected date
        }));
    };

    // Function to convert any date format to yyyy-mm-dd format
    const convertToYYYYMMDD = useCallback((dateString) => {
        // Create a new Date object from the input string
        const date = new Date(dateString);

        // Check if the input string is a valid date
        if (isNaN(date.getTime())) {
            return ''; // Return an empty string if the date is invalid
        }

        // Get the components of the date (year, month, day)
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if necessary
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if necessary

        // Return the date in yyyy-mm-dd format
        return `${year}-${month}-${day}`;
    }, []);

    // Updated handlePartSelectionChange function
    const handlePartSelectionChange = useCallback((e, index) => {
        const { value } = e.target;
        setInputData((prevData) => {
            const updatedLeavePartSelection = [...prevData.leave_part_selection];
            updatedLeavePartSelection[index] = value;
            return {
                ...prevData,
                leave_part_selection: updatedLeavePartSelection,
            };
        });
    }, [inputData]);

    // Function to generate an array of dates between start and end dates
    const generateDateRange = (startDate, endDate) => {
        const formattedStartDate = convertToYYYYMMDD(new Date(startDate));
        const formattedEndDate = convertToYYYYMMDD(new Date(endDate));
        const dates = [];
        let currentDate = new Date(formattedStartDate); // Create a new Date object
    
        while (currentDate <= new Date(formattedEndDate)) {
            dates.push(convertToYYYYMMDD(new Date(currentDate))); // Push a new Date object with the same value
            currentDate.setDate(currentDate.getDate() + 1); // Increment date by one day
        }
        return dates;
    };
    
    // function for the calulting the total days 
    const calculateHalfDayDuration = (start, end, partSelections) => {
        const oneDay = 8; // 8 hours count as one day
        const halfDayHours = 4; // 4 hours for half-day
    
        const startDate = new Date(start);
        const endDate = new Date(end);
    
        if (!start || !end) {
            return ''; // Return empty string if start or end date is not provided
        }
    
        // If part selections array is provided, check if all part selections are for half days
        if (Array.isArray(partSelections)) {
            const allHalfDays = partSelections.every(selection => selection === "First Half" || selection === "Second Half");
    
            // If all part selections are for half days, return "0 days 4 hours"
            if (allHalfDays) {
                return `0 days ${partSelections.length * halfDayHours} hours`;
            }
    
            // Calculate total duration considering selected half-days
            const selectedHalfDays = partSelections.filter(selection => selection === "First Half" || selection === "Second Half").length;
            const daysDifference = Math.ceil((endDate - startDate + 1) / (1000 * 60 * 60 * 24));
            let totalDuration = daysDifference * oneDay - selectedHalfDays * halfDayHours;
    
            // Calculate total days and remaining hours
            const totalDays = Math.floor(totalDuration / oneDay);
            const remainingHours = totalDuration % oneDay;
    
            return `${totalDays} days ${remainingHours} hours`;
        } else if (partSelections === "First Half" || partSelections === "Second Half") {
            // If part selection is "First Half" or "Second Half", return "0 days 4 hours"
            return `0 days ${halfDayHours} hours`;
        } else {
            // Calculate total duration normally
            const daysDifference = Math.ceil((endDate - startDate + 1) / (1000 * 60 * 60 * 24));
            const totalDuration = daysDifference * oneDay;
            const totalDays = Math.floor(totalDuration / oneDay);
            const remainingHours = totalDuration % oneDay;
            return `${totalDays} days ${remainingHours} hours`;
        }
    };
    
    const userExistsInProject = (project) => {
        // Retrieve the logged-in user's full name from localStorage
        const fullName = localStorage.getItem('employee_id');
        // Check if the user exists in the employee_select of the project
        return (fullName);
    };

    return (
        <>
            <div className='page-wrapper'>
                <div className='page-content'>
                    <PageTitle breadcrumbLast='Leave Approval' />
                    {Leaveid !== null ?
                    
                    <Container>

                                <React.Fragment  >
                                    <Row>
                                        <Col lg={8}>
                                            <Card>
                                                <Card.Body>
                                                    <Form>
                                                        <Row>
                                                            <Col md={12}>
                                                                <Form.Group className="custom-fr-group">
                                                                    <Form.Label>Employee Name</Form.Label>
                                                                    <Form.Control type="text"  name="leave_employee_name" id="leave_employee_name" disabled readOnly value={inputData.leave_employee_name} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="custom-fr-group" controlId="formGroupEmail" disabled readOnly>
                                                                    <Form.Label>Employee ID</Form.Label>
                                                                    <Form.Control type="email" placeholder="Enter the email..." list="emailsList" value = {inputData.employee_Id}  disabled readOnly />

                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="custom-fr-group" disabled readOnly>
                                                                    <Form.Label>Leave Type</Form.Label>
                                                                    <Form.Control type="text" name="leave_type" id="leave_type" value={inputData.leave_type} disabled readOnly  />

                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={4} sm={12}>
                                                                <Form.Group className="custom-fr-group" >
                                                                    <Form.Label>From</Form.Label>
                                                                        <div className="input-icon-content">
                                                                            <i className={`bi bi-calendar input-icon`} ></i>
                                                                        </div>
                                                                    <DatePicker
                                                                        
                                                                        onChange={(date) => handleChangeDate(date, "leave_start_date")}
                                                                        placeholderText="Select start date"
                                                                        dateFormat={formatDate(inputData.leave_start_date)}
                                                                        value={formatDate(inputData.leave_start_date)}
                                                                        disabled readOnly
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={4} sm={12}>
                                                                <Form.Group className="custom-fr-group">
                                                                    <Form.Label>To</Form.Label>
                                                                        <div className="input-icon-content">
                                                                            <i className={`bi bi-calendar input-icon`} ></i>
                                                                        </div>
                                                                    <DatePicker
                                                                        
                                                                        onChange={(date) => handleChangeDate(date, "leave_end_date")}
                                                                        placeholderText="Select end date"
                                                                        dateFormat={formatDate(inputData.leave_end_date)}
                                                                        value={formatDate(inputData.leave_end_date)}
                                                                        disabled readOnly
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={4} sm={12}>
                                                                <Form.Group className="custom-fr-group" disabled readOnly>
                                                                    <Form.Label>Total Days</Form.Label>
                                                                    <Form.Control type="text" name="leave_total_days" id="leave_total_days" value={inputData.leave_total_days} disabled readOnly  />

                                                                </Form.Group>
                                                            </Col>
                                                            <div className="custom-fr-group">
                                                                <div className="custom-fr-group">
                                                                    {generateDateRange(inputData.leave_start_date, inputData.leave_end_date, inputData.leave_part_selection).map((date, index) => (

                                                                        <div className="custom-fr-group">
                                                                            <div className="custom-fr-group">
                                                                                <div>
                                                                                    <Row className="mb-3 align-items-center">
                                                                                        <Col md={4}>
                                                                                            {formatDate(date)}
                                                                                        </Col>
                                                                                        <Col md={4}>
                                                                                        <Form.Select
                                                                                            onChange={(e) => handlePartSelectionChange(e)}
                                                                                            value={JSON.parse(inputData.leave_part_selection.replace(/'/g, '"'))[index]}
                                                                                            title=""
                                                                                            disabled
                                                                                            >
                                                                                            <option value="">Select</option>
                                                                                            <option value="Full Day" selected={inputData.leave_part_selection[index] === "Full Day" ? true : false} >Full Day</option>
                                                                                            <option value="First Half" selected={inputData.leave_part_selection[index] === "First Half" ? true : false}>First Half</option>
                                                                                            <option value="Second Half" selected={inputData.leave_part_selection[index] === "Second Half" ? true : false}>Second Half</option>
                                                                                                 
                                                                                        </Form.Select>
                                                                                        </Col>
                                                                                        <Col md={4} className="text-center">
                                                                                            <Form.Control type="email" placeholder="Enter email" readOnly title=""
                                                                                                value={calculateHalfDayDuration(
                                                                                                    convertToYYYYMMDD(date),
                                                                                                    convertToYYYYMMDD(date),
                                                                                                    JSON.parse(inputData.leave_part_selection.replace(/'/g, '"'))[index]                                                                                                
                                                                                                )}
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <Col md={12}>
                                                            <Form.Group className={`mb-3 ${!inputData.leave_description ? 'has-error' : ''}`} >
                                                                    <Form.Label>Reason For Leave<span className="text-danger">*</span></Form.Label>
                                                                    <Form.Control as="textarea" value = {inputData.leave_description} rows={2} disabled readOnly  />
                                                                    {errors.leave_description && <p className="text-danger">{errors.leave_description}</p>}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={12}>
                                                            {inputData.leave_status !== 'Approved' && (
                                                                <Form.Group className={`mb-3 ${!inputData.leave_rejection_reason ? 'has-error' : ''}`} >
                                                                    <Form.Label>Reason For Rejection<span className="text-danger">*</span> </Form.Label>
                                                                    <Form.Control 
                                                                        as="textarea" 
                                                                        rows={2} 
                                                                        value={inputData.leave_rejection_reason} 
                                                                        placeholder="Enter leave rejection reason..."
                                                                        name="leave_rejection_reason"
                                                                        id="leave_rejection_reason"
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.leave_rejection_reason && <p className="text-danger">{errors.leave_rejection_reason}</p>}
                                                                </Form.Group>
                                                            )}
                                                            </Col>
                                                            {showButtons && (
                                                            <Col className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                                <Button variant="primary" onClick={(event) => handleBothActions(event, Leaveid)} >
                                                                    Approved
                                                                </Button>
                                                                <Button variant="secondary" onClick={(event) => handleBothActions(event, Leaveid)}>
                                                                    Rejected
                                                                </Button>
                                                            </Col>
                                                            )}
                                                        </Row>
                                                    </Form>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg={4}> {/* Side panel */}
                                            <Card>
                                                <Card.Body>
                                                    <h5>Leave Statistics</h5>
                                                    <ul className="mb-0">
                                                        <li>Total Leave: {settingData && settingData.map(setting => setting.annualLeave)}</li>
                                                        <li>Booked Leave: {bookedLeave}</li>
                                                        <li>Available Leave: {availableLeave}</li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                    </Container>
                    : 
                    <Container>
                        {leaveidData.map((project ,index) => (
                            userExistsInProject(project) && (
                                <React.Fragment key={project.id}>
                                    <Row>
                                        <Col lg={8}>
                                            <Card>
                                                <Card.Body>
                                                    <Form>
                                                        <Row>
                                                            <Col md={12}>
                                                                <Form.Group className="custom-fr-group">
                                                                    <Form.Label>Employee Name</Form.Label>
                                                                    <Form.Control type="text"  name="leave_employee_name" id="leave_employee_name" disabled readOnly value={project.leave_employee_name} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="custom-fr-group" controlId="formGroupEmail" disabled readOnly>
                                                                    <Form.Label>Employee ID</Form.Label>
                                                                    <Form.Control type="email" placeholder="Enter the email..."  list="emailsList" value={project.employee_Id} disabled readOnly />

                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="custom-fr-group" disabled readOnly>
                                                                    <Form.Label>Leave Type</Form.Label>
                                                                    <Form.Control type="text" name="leave_type" id="leave_type" disabled readOnly value={project.leave_type} />

                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={4} sm={12}>
                                                                <Form.Group className="custom-fr-group" value={project.leave_start_date}>
                                                                    <Form.Label>From</Form.Label>
                                                                        <div className="input-icon-content">
                                                                            <i className={`bi bi-calendar input-icon`} ></i>
                                                                        </div>
                                                                    <DatePicker
                                                                        selected={project.leave_start_date ? new Date(project.leave_start_date) : null}
                                                                        onChange={(date) => handleChangeDate(date, "leave_start_date")}
                                                                        placeholderText="Select start date"
                                                                        dateFormat={formatDate(project.leave_start_date)}
                                                                        disabled readOnly
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={4} sm={12}>
                                                                <Form.Group className="custom-fr-group">
                                                                    <Form.Label>To</Form.Label>
                                                                        <div className="input-icon-content">
                                                                            <i className={`bi bi-calendar input-icon`} ></i>
                                                                        </div>
                                                                    <DatePicker
                                                                        selected={project.leave_end_date ? new Date(project.leave_end_date) : null}
                                                                        onChange={(date) => handleChangeDate(date, "leave_end_date")}
                                                                        placeholderText="Select end date"
                                                                        dateFormat={formatDate(project.leave_end_date)}
                                                                        disabled readOnly
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={4} sm={12}>
                                                                <Form.Group className="custom-fr-group" disabled readOnly>
                                                                    <Form.Label>Total Days</Form.Label>
                                                                    <Form.Control type="text" name="leave_total_days" id="leave_total_days" disabled readOnly value={project.leave_total_days} />

                                                                </Form.Group>
                                                            </Col>
                                                            <div className="custom-fr-group">
                                                                <div className="custom-fr-group">
                                                                    {generateDateRange(project.leave_start_date, project.leave_end_date, project.leave_part_selection).map((date, index) => (
                                                                        <div key={index} className="custom-fr-group">
                                                                            <div className="custom-fr-group">
                                                                                <div>
                                                                                    <Row className="mb-3 align-items-center">
                                                                                        <Col md={4}>
                                                                                            {formatDate(date)}
                                                                                        </Col>
                                                                                        <Col md={4}>
                                                                                        <Form.Select
                                                                                            onChange={(e) => handlePartSelectionChange(e, index)}
                                                                                            value={JSON.parse(project.leave_part_selection.replace(/'/g, '"'))[index]}
                                                                                            disabled
                                                                                            
                                                                                            >
                                                                                            <option value="">Select</option>
                                                                                            <option value="Full Day" selected={project.leave_part_selection[index] === "Full Day" ? true : false} >Full Day</option>
                                                                                            <option value="First Half" selected={project.leave_part_selection[index] === "First Half" ? true : false}>First Half</option>
                                                                                            <option value="Second Half" selected={project.leave_part_selection[index] === "Second Half" ? true : false}>Second Half</option>
                                                                                                
                                                                                        </Form.Select>
                                                                                        </Col>
                                                                                        <Col md={4} className="text-center">
                                                                                            <Form.Control type="email" placeholder="Enter email" readOnly title=""
                                                                                                value={calculateHalfDayDuration(
                                                                                                    convertToYYYYMMDD(date),
                                                                                                    convertToYYYYMMDD(date),
                                                                                                    JSON.parse(project.leave_part_selection.replace(/'/g, '"'))[index]
                                                                                                )}
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <Col md={12}>
                                                                <Form.Group className="custom-fr-group" controlId="reasonForm.Controlreason ">
                                                                    <Form.Label>Reason For Leave</Form.Label>
                                                                    <Form.Control as="textarea" rows={2} disabled readOnly value={project.leave_description} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={12}>
                                                            <Form.Group className={`mb-3 ${!inputData.leave_rejection_reason ? 'has-error' : ''}`} >
                                                                <Form.Label>Reason For Rejection<span className="text-danger">*</span> </Form.Label>
                                                                <Form.Control 
                                                                    as="textarea" 
                                                                    rows={2} 
                                                                    value={inputData.leave_rejection_reason} 
                                                                    placeholder="Enter leave rejection reason..."
                                                                    name="leave_rejection_reason"
                                                                    id="leave_rejection_reason"
                                                                    onChange={handleChange}
                                                                />
                                                                {errors.leave_rejection_reason && <p className="text-danger">{errors.leave_rejection_reason}</p>}
                                                            </Form.Group>
                                                        </Col>
                                                            <Col className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                                <Button variant="primary" onClick={(event) => handleBothActions(event, project.id)} >
                                                                    Approved
                                                                </Button>
                                                                <Button variant="secondary" onClick={(event) => handleBothActions(event, project.id)}>
                                                                    Rejected
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg={4}> {/* Side panel */}
                                            <Card>
                                                <Card.Body>
                                                    <h5>Leave Statistics</h5>
                                                    <ul className="mb-0">
                                                        <li>Total Leave: {settingData && settingData.map(setting => setting.annualLeave)}</li>
                                                        <li>Booked Leave: {bookedLeave}</li>
                                                        <li>Available Leave: {availableLeave}</li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </React.Fragment>)))}
                    </Container>
                }
                </div>
                <Footer />
            </div>
            <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
        </>
    )
}