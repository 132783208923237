import React, { useState, useEffect, useRef } from "react";
import PageTitle from "../layout/PageTitle";
import Footer from '../layout/Footer';
import { Table, Form, Button, Card, Container, Row, Col, Modal } from "react-bootstrap";
import { getFromAPI, postToAPI, showAlert, deleteFromAPI,putToAPI } from '../Utils/utils';
import { useNavigate, Navigate } from "react-router-dom";

// /DataTableS
import JSZip from "jszip";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DepartmentList() {

  // State for Department and input Data.
  const [departmentAddedSuccessfully, setDepartmentAddedSuccessfully] = useState(false);
  const [DepartmentListData, SetDepartmentListData] = useState([]);
  const navigate = useNavigate();
  const tableRef = useRef(null); // Ref for DataTable instance
  const [inputData, setInputData] = useState({
    DepartmentName: '',
    DepartmentDescription: '',
  });

  // This function runs when the component mounts
  useEffect (() =>{
    // It sets the document title to 'Department | PMRS App'
    document.title = 'Department | PMRS App';
    // This return statement defines a cleanup function
    return ()=> {
      // This function runs when the component unmounts
      document.title = 'PMRS App';
      // It resets the document title back to 'PMRS App'
    };
  },[]);

   // Effect to display success message after Department addition  
   useEffect(() => {
    if (departmentAddedSuccessfully) {
      toast.success("Department added successfully!", 'success');
      setTimeout(() => {
        setDepartmentAddedSuccessfully(false);
      }, 3000); // Clear the success message after 3 seconds
    }
  }, [departmentAddedSuccessfully]);

 // State for edited data
  const [EditedData] = useState({
    ProjectStatusID: '',
  });

  // For get data
  useEffect(() => {

    // Navigate to Login page if no Employee ID Found
    const User_Id = localStorage.getItem('employee_id');
    const isPositionAdmin = localStorage.getItem('Position');
    if (User_Id === null || isPositionAdmin !== 'Admin') {
      navigate('/');
    }

    const fetchDepartmentData = async () => {
      try {
        const response = await getFromAPI("department/");
        SetDepartmentListData(response);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchDepartmentData(); 
  }, []);

  // Datatable  
  DataTable.Buttons.jszip(JSZip);
  // Initialize DataTable when department data changes
  useEffect(() => {
    // Initialize or update DataTable when milestoneData changes
    if (tableRef.current) {
      // If DataTable has already been initialized, destroy it first
      tableRef.current.clear().destroy();
    }
  
    // Initialize DataTable
    const dataTableInstance = $('.maindatatable').DataTable({
      dom: 'flrtip',
      language: {
        search: '',
        searchPlaceholder: 'Search...',
        paginate: {
          previous: '«',
          next: '»',
        },
      },
      data: DepartmentListData, // Add milestoneData directly to the DataTable
      columns: [
        { data: 'department_name' },
        { data: 'department_description' },
        {
          data: null,
          render: function (data, type, row) {
            return `
              <div class="d-flex">
                <button class="btn btn-primary table-btn me-2" data-id="${row.id}" data-action="edit"><i class="bi bi-pencil-square"></i></button>
                <button class="btn btn-danger table-btn" data-id="${row.id}" data-action="delete"><i class="bi bi-trash3"></i></button>
              </div>
            `;
          }
        }
      ]
    });
  
    // Store the DataTable instance in the ref
    tableRef.current = dataTableInstance;
    // Attach event listeners to handle edit and delete actions
    $(document.body).on('click', '.btn.table-btn', function() {
      const action = $(this).data('action');
      const id = $(this).data('id');
      if (action === 'edit') {
        HandleEditActions(id);
      } else if (action === 'delete') {
        handleDelete(id);
      }
    });
  }, [DepartmentListData]);
  
  // Function to handle adding a department
  const OnAddingDepartment = async () => {
    try{
        // Validation checks for null values
        if (!inputData.DepartmentName) {
          toast.error("Department Name is required.", 'error');
          return;
        } else if (!inputData.DepartmentDescription) {
          toast.error("Department Description is required.", 'error');
          return;
        }
      
        // If not a duplicate and no null values, proceed with the API call
        const response = await postToAPI("department/", {
          department_name: inputData.DepartmentName,
          department_description: inputData.DepartmentDescription,
        });

        if (response.status) {
          SetDepartmentListData(response.data);
          setDepartmentAddedSuccessfully(true); // Set the state variable to true
        }
      } catch (error) {
      console.error("Error adding Department:", error);
    }
  };
  
  //function for updatinf data in data in edit time 
  async function updateDataInDatabase(itemDetails) {
    const updateUrl = `department/${EditedData.EditedDepartmentID}/`;

      const requestBody = {
        department_name: itemDetails.DepartmentName || null,
        department_description: itemDetails.DepartmentDescription || null,
      };

      try {
        // Make a PUT request to update the data
        const data = await putToAPI(updateUrl, requestBody);
          if (data.message === 'Item updated successfully') {
            SetDepartmentListData(data.data);
          // setDepartmentEditedSuccessfully(true); // Set the state variable to true
          } else {
            console.error("Unexpected response:", data.message);
          }
      } catch (error) {
        console.error('Error updating item:', error);
      }

    EditedData.ProjectStatusID = '';
  }
  
  // Function to handle Department deletion
  const handleDelete = async (itemId) => {
      try {
        // Show confirmation dialog before deleting
        showAlert("Are you sure you want to delete this Department?", "confirm", async (isConfirmed) => {
          if (!isConfirmed) {
            return; // If not confirmed, do nothing
          }

          try {
            const response = await deleteFromAPI(`department/${itemId}/`);

            if (response.message === "Item deleted successfully") {
              SetDepartmentListData(response.data);
              toast.success(' Department deleted successfully!', 'success');
            } else {
              console.error("Unexpected response:", response.message);
            }
          } catch (error) {
            console.error("Error deleting Department:", error);
          }
        });
      } catch (error) {
        console.error("Error showing delete confirmation:", error);
      }

    EditedData.ProjectStatusID = '';
  };

    // for updating values in the database
    async function updateDataInDatabase(itemDetails) {
      const updateUrl = `department/${EditedData.ProjectStatusID}/`;

        // Check if any required field is null
        if (!itemDetails.DepartmentName || !itemDetails.DepartmentDescription) {
          toast.error("All fields are required.", 'error');
          return; // Stop further execution
        }

        const requestBody = {
          department_name: itemDetails.DepartmentName,
          department_description: itemDetails.DepartmentDescription,
        };

        try {
          // Make a PUT request to update the data
          const data = await putToAPI(updateUrl, requestBody);

          if (data.message === 'Item updated successfully') {
            SetDepartmentListData(data.data);
            toast.success("Department updated successfully!", 'success');
          } else {
            console.error("Unexpected response:", data.message);
          }
        } catch (error) {
          console.error('Error updating item:', error);
        }

      // Reset the EditedDepartmentID after updating
      EditedData.ProjectStatusID = '';
    }

  // Function to handle input changes
  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

   // Function to handle adding a department
   const handleAddDepartment = () => {
    // Check if any field in the inputData is empty
    const isAnyFieldEmpty = Object.values(inputData).some(value => value === '');
    if (!inputData.DepartmentName || inputData.DepartmentName.trim() === "") {
      toast.error("Department Name is required!",'error')
      return;
    }
    if (!inputData.DepartmentDescription || inputData.DepartmentDescription.trim() === "") {
      toast.error("Department Description is required!",'error')
      return;
    }

    // Check for duplicate entry before making the API call
    const isDuplicate = DepartmentListData.some((department) => (department.department_name === inputData.DepartmentName) && (department.id !== EditedData.ProjectStatusID));
    if (isDuplicate) {
      toast.warn('Department already exists. Please choose a different Department name.', 'error');
      return;
    }

    if (isAnyFieldEmpty) {
        toast.error('All fields are required', 'error');
    } else {
          if (EditedData.ProjectStatusID === '') {
            OnAddingDepartment();
          } else {
            if (!inputData.DepartmentName || inputData.DepartmentName.trim() === "") {
              toast.error("Department Name is required!",'error')
              return;
            }
            if (!inputData.DepartmentDescription || inputData.DepartmentDescription.trim() === "") {
              toast.error("Department Description is required!",'error')
              return;
            }
            else {
            updateDataInDatabase(inputData)
            }
          }
        handleCloseModalOne();
     }
  };

  // Function to handle edit actions
  const HandleEditActions = (id) => {
    OnEditDepartment(id);
    handleShowModalOne();
  };

    // Code to Update set Edit dialog data
    const OnEditDepartment = async (itemId) => {
      try {
        const response = await getFromAPI(`department/${itemId}/`);
        const jsonData = JSON.parse(response.data);
          if (response.status) {
            EditedData.ProjectStatusID = itemId;
            updateModalContent(jsonData[0].fields);
          } else {
            toast.error("Some Error Occurred...");
          }
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

   // Function to Update Modal Contect when click on edit button
    function updateModalContent(itemDetails) {
      setInputData({
        DepartmentName: itemDetails.department_name || "",
        DepartmentDescription: itemDetails.department_description || "",
      });
    }

  // State for showing or hiding modal
  const [showModalOne, setShowModalOne] = useState(false);
  const handleCloseModalOne = () =>{
    setShowModalOne(false);
    EditedData.ProjectStatusID = '';
  };
  const handleShowModalOne = () => setShowModalOne(true);
  
  const resetInputData = () => {
    setInputData({
      DepartmentName: '',
      DepartmentDescription: '',
    });
  };
  
  // Function to handle adding a department
  const ADDDepartment = () => {
    resetInputData(); // Reset input data when opening the modal for adding
    handleShowModalOne(); // Show the modal
  };
  
  return (
    <div className="page-wrapper">
      <div className="page-content">
        <PageTitle breadcrumbLast="Department" />
        <Container>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <Card.Title className='mb-lg-0'>Department List</Card.Title>
                    <div className="d-md-flex">
                      <Button variant="soft-success mb-1 mb-md-0" className="btn-icon" onClick={ADDDepartment}>
                        <i className="bi bi-plus"></i>
                      </Button>
                    </div>
                  </div>
                  {/* End of card title */}
                  <Table
                    responsive
                    bordered
                    className="mb-0 table-nowrap w-100 maindatatable">
                    <thead className="table-light">
                      <tr>
                        <th>Department name</th>
                        <th>Description</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
      <Modal centered show={showModalOne} onHide={handleCloseModalOne} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>{EditedData.ProjectStatusID ? 'Edit Department Details' : 'Add Department Details'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>
                <Form>
                  <Col md={12}>
                    <Form.Group className="custom-fr-group">
                      <Form.Label>Department Name </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Department Name"
                        required
                        name="DepartmentName"
                        value={inputData.DepartmentName}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="custom-fr-group">
                      <Form.Label>Department Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Department description..."
                        as="textarea"
                        rows={4}
                        name="DepartmentDescription"
                        id="department_description"
                        value={inputData.DepartmentDescription}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="primary" onClick={handleAddDepartment}>
            Ok
          </Button>
          <Button variant="secondary" onClick={handleCloseModalOne}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer position="top-left" autoClose={3000} hideProgressBar theme="colored" />
    </div>
  );
}