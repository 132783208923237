import React, { useState } from 'react';
import MenuContext from './MenuContext';

const MenuState = (props) => {
    const [iconMenu, setIconMenu] = useState(true);

    const handleClickOnLeftSideBar = () => {
        setIconMenu(!iconMenu)
    }

    // const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    // const setWindowDimensions = () => {
    //     setWindowWidth(window.innerWidth)
    // }


    // useEffect(() => {
    //     return () => {
    //         window.removeEventListener('resize', setWindowDimensions)
    //     }
    // }, [])
    // console.log(windowWidth);

    // if(windowWidth > 768 && windowWidth < 1400)
    // {
    //     setIconMenu(false);
    // } else if (windowWidth > 1400) {
    //     setIconMenu(true);
    // }

    return (
        <MenuContext.Provider
            value={{ iconMenu, handleClickOnLeftSideBar }}
        >
            {props.children}
        </MenuContext.Provider>
    );
};

export default MenuState;