import React, { useState, useEffect, useContext } from "react";
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { getFromAPI } from "../Utils/utils";
import { useDateFormat } from "../../context/DateFormatContext.js";
//Datatable
import "datatables.net-dt";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, Navigate } from "react-router-dom";
import user from "../../images/user1.png";
// chart
import Chart from "react-apexcharts";
import charts from "./ProjectChart";
import { useParams } from 'react-router-dom';
export default function Projects() {

  // This function runs when the component mounts
  useEffect (() =>{
    // It sets the document title to 'Projects | PMRS App'
    document.title = 'Projects | PMRS App';
    // This return statement defines a cleanup function
    return ()=> {
      // This function runs when the component unmounts
      document.title = 'PMRS App';
      // It resets the document title back to 'PMRS App'
    };
  },[]);
  
  const [adminproject, setAdminProject] = useState([]);
  const [employeeListData, setEmployeeListData] = useState([]);
  const { selectedFormat } = useDateFormat();
  const navigate = useNavigate();
  const { id } = useParams();

  
  // Fetch data from project
  useEffect(() => {

    // Navigate to Login page if no Employee ID Found
    const User_Id = localStorage.getItem('employee_id');
    if (User_Id === null) {
      navigate('/');
    }

    async function fetchData() {
      try {
        // Fetch employee data
        const response = await getFromAPI("project/");
        

        if(id){
        const respose34 = await getFromAPI(`project/${id}/`)
        // Parse the JSON data string inside response34
        const data = JSON.parse(respose34.data);  
        setAdminProject([data[0].fields]);}
        else{
          setAdminProject(response)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, [id]);

  // Fetch data from employee
  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch employee data
        const employeeData = await getFromAPI("employee/");
        const updatedEmployeeListData = employeeData.map((employee) => ({
          value: employee.employee_id,
          label: `${employee.first_name} ${employee.last_name} - ${employee.role}`,
          profileImage: employee.profile_picture,
        }));
        setEmployeeListData(updatedEmployeeListData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);
 // Fetch status number to display in chart 
  const getStatusNumber = (status) => {
    if (!status) {
      return 0; // Return 0 if status is undefined or null
    }

    const percentageIndex = status.indexOf("%");
    if (percentageIndex !== -1) {
      // Extract numeric value without the percentage sign
      const numericValue = parseInt(status.substring(0, percentageIndex));
      return isNaN(numericValue) ? 0 : numericValue;
    }

    switch (status) {
      case "Bug Fixing Phase":
        return 60;
      case "Delivered To Customer":
        return 80;
      case "Designing Phase":
        return 10;
      case "Development Phase":
        return 45;
      case "In UAT Phase":
        return 70;
      case "On Hold":
        return 100;                                       // still the bard is need to set temp its 100%
      case "Project Drop":
        return 100;                                       // still the bard is need to set temp its 100%
      case "Project Not Assigned to Member":
        return 15;
      case "Testing Phase":
        return 60;
      case "UI Development Phase":
        return 30;
      case "Waiting for Customer Response":
        return 75;
      case "Project Completed":
        return 100;
      case "Invoice Generated":
        return 85;
      case "Waiting for Payment":
        return 90;
      default:
        return 0; // Default to 0% width if status doesn't match
    }
  };

  // Function to check if the logged-in user exists in the employee_select of a project
  const userExistsInProject = (project) => {
    // Retrieve the logged-in user's full name from localStorage
    const fullName = localStorage.getItem('employee_id') ;
    // Check if the user exists in the employee_select of the project
    return project.employee_select.split(",").includes(fullName);
  };
  
  // Function to format the date based on the selected format
  const formatDate = (dateString) => {
    // If the dateString is empty or null, return an empty string
    if (!dateString) return '';

    // Create a new Date object with the parts in 'MM-DD-YYYY' format
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.error(`Invalid date: ${dateString}`);
      return ''; // Return an empty string if the date is invalid
    }

    // Get the date components
    const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero if necessary
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Pad with leading zero if necessary
    const year = date.getFullYear();

    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }; // Define the default format

    // Custom formats based on the selected format
    switch (selectedFormat) {
      case 'YYYY-MM-DD':
        // Use custom format for 'YYYY-MM-DD'
        return year + "-" + month + "-" + day;
      case 'DD-MM-YYYY':
        // Use custom format for 'DD-MM-YYYY'
        return day + "-" + month + "-" + year;
      case 'MM-DD-YYYY':
        // Use custom format for 'MM-DD-YYYY'
        return month + "-" + day + "-" + year;
      // Add more cases for additional formats as needed
      default:
        // Default to the standard format
        return date.toLocaleDateString('en-GB', options);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <PageTitle breadcrumbLast="Projects" />
          <Container>
            <Row>
              {adminproject.map((project) => (
                userExistsInProject(project) && (
                <React.Fragment key={project.id}>
                  <Col lg={8} className="mb-4">
                    <Card className="mb-0 h-100">
                      <Card.Body>
                        <Card.Title>
                          Project Overview - {project.projectname}
                        </Card.Title>
                        <Row>
                          <Col lg={6}>
                            <ul className="list-unstyled fs-18 mb-0">
                              <li className="mb-3">
                                Project Name:{" "}
                                <span className="text-muted">
                                  {project.projectname}
                                </span>
                              </li>
                              <li className="mb-3">
                                Project Status:{" "}
                                <span className="text-muted">
                                  {project.projectstatus}
                                </span>
                              </li>
                              {/* <li className="mb-3">
                                Projects Customer :{" "}
                                <span className="text-muted">Bob Toly</span>
                              </li> */}
                              <li className="mb-3">
                                Start Date:{" "}
                                <span className="text-muted">
                                  {formatDate(project.startdate)}
                                </span>
                              </li>
                              <li className="mb-3">
                                End Date:{" "}
                                <span className="text-muted">
                                  {formatDate(project.enddate)}
                                </span>
                              </li>
                              {/* <li className="">
                                Projects Estimated Hours :{" "}
                                <span className="text-muted"> 260 Hours</span>
                              </li> */}
                            </ul>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <h5 className="mt-24">Project Team</h5>
                              {/* <SimpleBar style={{ maxHeight: 198 }}>
                                <ul className="list-unstyled">
                                  {project.employee_select
                                    .split(",")
                                    .map((employeeId, index) => {
                                      const employee = employeeListData.find(
                                        (emp) =>
                                          emp.value === parseInt(employeeId, 10)
                                      );
                                      return (
                                        <li key={index} className="c-mb-6">
                                          {employee && (
                                            <>
                                              <img
                                                //src={getAvatarForMember(index)}
                                                // src={Avatar5}
                                                className="rounded-circle img-thumbnail avatar-img-sm c-me-12"
                                                alt=""
                                              />
                                              {employee.label}
                                            </>
                                          )}
                                        </li>
                                      );
                                    })}
                                </ul>
                              </SimpleBar> */}
                              <SimpleBar style={{ maxHeight: 198 }}>
                                <ul className="list-unstyled">
                                  {project.employee_select.split(",").map((employeeId, index) => {
                                    const employee = employeeListData.find(
                                      (emp) => emp.value === parseInt(employeeId, 10)
                                    );
                                    if (employee) {
                                      const profileImage = employee.profileImage ? employee.profileImage : user;
                                      return (
                                        <li key={index} className="c-mb-6">
                                          <img
                                            src={profileImage}
                                            className="rounded-circle img-thumbnail avatar-img-sm c-me-12"
                                            alt=""
                                            style={{ width: '25px', height: '25px', objectFit: 'cover', borderRadius: '50%' }}
                                          />
                                          {employee.label}
                                        </li>
                                      );
                                    }
                                    return null; // Return null if employee not found
                                  })}
                                </ul>
                              </SimpleBar>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={4} className="mb-4">
                    <Card className="mb-0 h-100">
                      <Card.Body>
                        <Card.Title>Project Progress</Card.Title>
                        <div id="chart">
                          <Chart
                            series={[getStatusNumber(project.projectstatus)]} // Use the statusNumber as the value in the series array
                            options={charts.progressChart.options}
                            type="radialBar"
                            height={300}
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </React.Fragment>
                )
              ))}
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}
