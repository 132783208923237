import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import ThumbsUpSticker1 from '../../images/sticker/thumbs-up-sticker1.png'

//const API_BASE_URL = 'http://127.0.0.1:8000/api/manage_data/';
const API_BASE_URL = 'https://uat.pmrs.backend.foogletech.com/api/manage_data/';


/**
 * Handles the response from an HTTP request.
 * If the response is not okay, it throws an error with the error message from the server.
 * @param {Response} response - The HTTP response object.
 * @returns {Promise<Object>} - A promise that resolves to the JSON response.
 */
export async function handleResponse(response) {
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Request failed');
  }
  return response.json();
}

/**
 * Makes a GET request to an API endpoint with optional query parameters.
 * @param {string} endpoint - The API endpoint.
 * @param {Object} queryParams - Optional query parameters.
 * @returns {Promise<Object>} - A promise that resolves to the JSON response.
 */
export async function getFromAPI(endpoint, employee_id , queryParams = {}) {
  try {
    const queryString = new URLSearchParams(queryParams).toString();
    const url = `${API_BASE_URL}${endpoint}${queryString ? `?${queryString}` : ''}`;
    
    const response = await fetch(url, {
      method: 'GET',
      // credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
}

/**
 * Makes a POST request to an API endpoint.
 * @param {string} endpoint - The API endpoint.
 * @param {Object} data - The data to be sent in the request body.
 * @returns {Promise<Object>} - A promise that resolves to the JSON response.
 */
export async function postToAPI(endpoint, data) {
  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      method: 'POST',
      //credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
}

export async function sendNotification(endpoint,type, message, creation_time, user, is_read) {
  try {
    const notificationData = {
      type,
      message,
      creation_time,
      user,
      is_read
    };

    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(notificationData),
    });
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
}

/**
 * Makes a PUT request to an API endpoint for updating data.
 * @param {string} endpoint - The API endpoint.
 * @param {Object} data - The data to be sent in the request body for updating.
 * @returns {Promise<Object>} - A promise that resolves to the JSON response.
 */
export async function putToAPI(endpoint, data) {
  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      method: 'PUT',
      //credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
}

/**
 * Makes a DELETE request to an API endpoint.
 * @param {string} endpoint - The API endpoint.
 * @returns {Promise<Object>} - A promise that resolves to the JSON response.
 */
export async function deleteFromAPI(endpoint) {
  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      method: 'DELETE',
      //credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
}

export function showAlert(message, type = 'error', callback) {
  let title = 'Error!';
  let icon = 'error';

  if (type === 'success') {
    title = 'Success!';
    icon = 'success';
  } else if (type === 'info') {
    title = 'Info';
    icon = 'info';
  } else if (type === 'warning') {
    title = 'Warning';
    icon = 'warning';
  } else if (type === 'confirm') {
    SweetAlert.fire({
      icon: 'question',
      title: 'Confirm',
      text: message,
      padding: 0,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked 'Yes'
        callback(true);
      } else {
        // User clicked 'No' or closed the dialog
        callback(false);
      }
    });
    return;
  }

  //Regular alert
  // SweetAlert.fire({
  //   icon,
  //   title: title,
  //   text: message,
  //   position: "center",
  //   showConfirmButton: false,
  //   timer: 1000,
  // });

  toast({
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

}