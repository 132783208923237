import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import Select from "react-select";
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import {
  Table,
  Form,
  Button,
  Badge,
  Card,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, Navigate } from "react-router-dom";
import user from "../../images/user1.png";
// Datatable
import JSZip from "jszip";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import ProgressBar from "react-bootstrap/ProgressBar";
import DatePicker from 'react-datepicker';
import { useDateFormat } from "../../context/DateFormatContext.js";
import 'react-datepicker/dist/react-datepicker.css';
import {
  sendNotification,
  getFromAPI,
  postToAPI,
  putToAPI,
  deleteFromAPI,
  showAlert,
} from "../Utils/utils.js"; // Import utility functions

const AdminProject = () => {

  const navigate = useNavigate();

  // This function runs when the component mounts
  useEffect(() => {
    // It sets the document title to 'Admin Project | PMRS App'
    document.title = 'Admin Project | PMRS App';
    // This return statement defines a cleanup function
    return () => {
      // This function runs when the component unmounts
      document.title = 'PMRS App';
      // It resets the document title back to 'PMRS App'
    };
  }, []);

  // fucntion for set progressbar colour  
  const getProgressColor = (status) => {
    switch (status) {
      case "Designing Phase":
        return "light";
      case "Project Not Assigned to Member":
        return "secondary";
      case "UI Development Phase":
        return "primary";
      case "Development Phase":
        return "warning";
      case "Testing Phase":
        return "info";
      case "Bug Fixing Phase":
        return "danger";
      case "In UAT Phase":
        return "orange";
      case "Waiting for Customer Response":
        return "pink";
      case "Delivered To Customer":
        return "teal";
      case "Invoice Generated":
        return "purple";
      case "Waiting for Payment":
        return "dark";
      case "Project Completed":
        return "success";
      case "On Hold":
        return "red";
      case "Project Drop":
        return "purple";
      default:
        return "info";
    }
  };

  // fucntion for set progress bar width
  const getProgressWidth = (status) => {
    switch (status) {
      case "Bug Fixing Phase":
        return 60;
      case "Delivered To Customer":
        return 80;
      case "Designing Phase":
        return 10;
      case "Development Phase":
        return 45;
      case "In UAT Phase":
        return 70;
      case "On Hold":
        return 100;
      case "Project Drop":
        return 100;
      case "Project Not Assigned to Member":
        return 15;
      case "Testing Phase":
        return 60;
      case "UI Development Phase":
        return 30;
      case "Waiting for Customer Response":
        return 75;
      case "Project Completed":
        return 100;
      case "Invoice Generated":
        return 85;
      case "Waiting for Payment":
        return 90;
      default:
        return 0; // Default to 0% width if status doesn't match
    }
  };

  // Fetching all project data
  const initialData = [];
  const [AdminProjectData, SetAdminProjectData] = useState(initialData);
  const [Project_status, setProject_status] = useState(initialData);
  const [employeeListData, setEmployeeListData] = useState(initialData);
  const [editedTeamID, setEditedTeamID] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { selectedFormat } = useDateFormat();
  const tableRef = useRef(null);
  const [oldprojectname ,setOldProjectName]=useState();
  const [oldprojectmember ,setOldProjectMember]= useState();
  const [oldprojectstatus ,setOldProjectStatus]=useState();
  const [inputData, setInputData] = useState({
    projectname: '',
    projectdescription: '',
    projectstatus: '',
    startdate: '',
    enddate: '',
    project_type: '',
    project_price: '',
    employee_select: [], // Store selected employees as an array
    reason_for_hold: '', //Add field reason for hold

  });

  // Temporary disabled untill task module is implemented
  const staticProjectStatusData = [
    {
      project_status_name: "Bug Fixing Phase ", project_status_description: "60%", select_color: "Danger",
    },
    {
      project_status_name: "Invoice Generated", project_status_description: "85%", select_color: "Purple",
    },
    {
      project_status_name: "Waiting for Payment", project_status_description: "90%", select_color: "Dark",
    },
    {
      project_status_name: "Project Completed", project_status_description: "100%", select_color: "Success",
    },
    {
      project_status_name: "Delivered To Customer", project_status_description: "80%", select_color: "Teal",
    },
    {
      project_status_name: "Designing Phase", project_status_description: "10%", select_color: "Light",
    },
    {
      project_status_name: "Development Phase", project_status_description: "45%", select_color: "Warning",
    },
    {
      project_status_name: "In UAT Phase", project_status_description: "70%", select_color: "Orange",
    },
    {
      project_status_name: "On Hold", project_status_description: "-", select_color: "Red",
    },
    {
      project_status_name: "Project Not Assigned to Member", project_status_description: "15%", select_color: "Secondary",
    },
    {
      project_status_name: "Testing Phase", project_status_description: "50%", select_color: "Cyan",
    },
    {
      project_status_name: "UI Development Phase", project_status_description: "30%", select_color: "Primary",
    },
    {
      project_status_name: "Waiting for Customer Response", project_status_description: "75%", select_color: "Pink",
    },
    {
      project_status_name: "Project Drop", project_status_description: "-", select_color: "Purple",
    }
  ];


  useEffect(() => {
    async function fetchData() {
      try {
        // Navigate to Login page if no Employee ID Found
        const User_Id = localStorage.getItem('employee_id');
        const isPositionAdmin = localStorage.getItem('Position');
        if (User_Id === null || isPositionAdmin !== 'Admin') {
          navigate('/');
         }

        // Fetch team data
        const ProjectData = await getFromAPI('project/');
        SetAdminProjectData(ProjectData);
        // const ProjectStatusData = await getFromAPI('projectstatus/');
        // Temporary disabled untill task module is implemented
        //setProject_status(ProjectStatusData)
        setProject_status(staticProjectStatusData);
        // Fetch employee data
        const employeeData = await getFromAPI('employee/');
        const updatedEmployeeListData = employeeData.map(employee => ({
          value: employee.employee_id,
          label: `${employee.first_name} ${employee.last_name}`,
          profileImage: employee.profile_picture,
          first_name: employee.first_name,
          last_name: employee.last_name
        }));
        setEmployeeListData(updatedEmployeeListData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  // Initialize DataTable on milestoneData change
  useEffect(() => {
    // Initialize or update DataTable when milestoneData changes
    if (tableRef.current) {
      // If DataTable has already been initialized, destroy it first
      tableRef.current.clear().destroy();
    }

    // Initialize DataTable
    const dataTableInstance = $('.maindatatable').DataTable({
      dom: 'flrtip',
      language: {
        search: '',
        searchPlaceholder: 'Search...',
        paginate: {
          previous: '«',
          next: '»',
        },
      },
      data: AdminProjectData,
       // Add milestoneData directly to the DataTable
      columns: [
        { data: 'projectname',
        render: function (data, type, row) {
          const isPastDueAndNotCompleted = isPastDue(row.enddate) && row.projectstatus !== "Project Completed";
          const isHold = row.projectstatus === "On Hold";
          const isDrop = row.projectstatus === "Project Drop";
          let badgeClass = "";
          if (isHold) {
            badgeClass = " text-warning";
          } else if (isDrop) {
            badgeClass = " text-orange";
          } else if (isPastDueAndNotCompleted) {
            badgeClass = " text-danger";
          }
          return (
            `<span class="${badgeClass}">${data}</span>`
          );}
      },
        {
          data: null,
          render: function (data, type, row) {
            const isPastDueAndNotCompleted = isPastDue(row.enddate) && row.projectstatus !== "Project Completed";
            const isHold = row.projectstatus === "On Hold";
            const isDrop = row.projectstatus === "Project Drop";
            const returnvalue = formatDate(row.startdate) + ' To ' + formatDate(row.enddate);
            let badgeClass = "";
            if (isHold) {
              badgeClass = " text-warning";
            } else if (isDrop) {
              badgeClass = " text-orange";
            } else if (isPastDueAndNotCompleted) {
              badgeClass = " text-danger";
            }
            return (
              `<span class="${badgeClass}">${returnvalue}</span>`
            );}
        },
        {
          data: null,
          render: function (data, type, row) {
            const isPastDueAndNotCompleted = isPastDue(row.enddate) && row.projectstatus !== "Project Completed";
            const isHold = row.projectstatus === "On Hold";
            const isDrop = row.projectstatus === "Project Drop";
            const returnvalue = szcalculateDuration(convertToYYYYMMDD(row.startdate), convertToYYYYMMDD(row.enddate));
            let badgeClass = "";
            if (isHold) {
              badgeClass = " text-warning";
            } else if (isDrop) {
              badgeClass = " text-orange";
            } else if (isPastDueAndNotCompleted) {
              badgeClass = " text-danger";
            }
            return (
              `<span class="${badgeClass}">${returnvalue}</span>`
            );}
        },
        {
          data: 'project_type',
          render: function (data, type, row) {
            const isPastDueAndNotCompleted = isPastDue(row.enddate) && row.projectstatus !== "Project Completed";
            const isHold = row.projectstatus === "On Hold";
            const isDrop = row.projectstatus === "Project Drop";
            let badgeClass = "";
            if (isHold) {
              badgeClass = " text-warning";
            } else if (isDrop) {
              badgeClass = " text-orange";
            } else if (isPastDueAndNotCompleted) {
              badgeClass = " text-danger";
            }
            return (
              `<span class="${badgeClass}">${data}</span>`
            );}
        },
        {
          data: 'employee_select',
          render: function (data, type, row) {
            const ids = data.split(',');
            let imgHTML = '';
            // Loop through each employee ID
            ids.forEach(employeeId => {
                // Find the employee object with the corresponding ID
                const employee = employeeListData.find(emp => emp.value === parseInt(employeeId, 10));
                // Check if the employee object is found
                if (employee) {
                    // Check if the profileImage attribute exists
                    const profileImage = employee.profileImage ? employee.profileImage: user;
                    if (profileImage) {
                        // If profileImage exists, display the image wrapped in a div
                        imgHTML += `<img className="auth-img rounded-circle" src="${profileImage}" style="width: 25px; height: 25px; object-fit: cover; border-radius: 50%;" />`;
                    } else {
                        // If profileImage doesn't exist, display a blank circle with grey border wrapped in a div
                        imgHTML += `<img className="auth-img rounded-circle" src="${profileImage}" style="width: 25px; height: 25px; object-fit: cover; border-radius: 50%;" />`;
                    }
                }
            });
            // Return the HTML for displaying profile pictures
            return `<div class="text-dark" style="display: inline-block;">${imgHTML}</div>`;
            }
        },
        {
          data: 'projectstatus',
          render: function (data, type, row) {
            const status = row.projectstatus ? row.projectstatus : null;
            const isPastDueAndNotCompleted = isPastDue(row.enddate) && row.projectstatus !== "Project Completed";
            const isHold = row.projectstatus === "On Hold";
            const isDrop = row.projectstatus === "Project Drop";
            let badgeClass = "badge badge-sm bg-soft-info";
            if (isHold) {
              badgeClass = "badge badge-sm bg-soft-warning";
            } else if (isDrop) {
              badgeClass = "badge badge-sm bg-soft-orange";
            } else if (isPastDueAndNotCompleted) {
              badgeClass = "badge badge-sm bg-soft-danger";
            }
            return (
              `<span class="${badgeClass}">${status}</span>`
            );
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            const progressColor = getProgressColor(row.projectstatus);
            const progressWidth = getProgressWidth(row.projectstatus);
            return `<div class="progress" style= "height : 8px;">
                           <ProgressBar
                                  style="width: ${progressWidth}%; height : 8px;"
                                  class="progressbar-sm bg-${progressColor}"
                                />  
                         </div>
                     `;
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return `
              <div class="d-flex">
                <button class="btn btn-primary table-btn me-2" data-id='${JSON.stringify(row)}' data-action="edit"><i class="bi bi-pencil-square"></i></button>
                <button class="btn btn-danger table-btn" data-id='${JSON.stringify(row)}' data-action="delete"><i class="bi bi-trash3"></i></button>
              </div>
            `;
          }
        }
      ]
    });

    // Store the DataTable instance in the ref
    tableRef.current = dataTableInstance;
    // Attach event listeners to handle edit and delete actions
    $(document.body).on('click', '.btn.table-btn', function () {
      const action = $(this).data('action');
      const rowData = $(this).data('id');
      if (action === 'edit') {
        handleEditClick(rowData);
      } else if (action === 'delete') {
        handleDeleteAdmiprojectData(rowData.id);
      }
    });
  }, [AdminProjectData, employeeListData]);

  //function for the deleting the milestone
  const handleDeleteAdmiprojectData = async (milestoneId) => {
    try {
      showAlert('Are you sure you want to delete this Project ?', 'confirm', (result) => {
        if (result) {
          // User confirmed the deletion
          deleteadminporject(milestoneId);
        } else {
          // User canceled the deletion
          // You can handle this case if needed
        }
      });
    } catch (error) {
      toast.error('Error deleting Project', 'error');
    }
  };

  //function for the deleting the milestone form the database
  const deleteadminporject = async (milestoneId) => {
    try {
      const response = await deleteFromAPI(`project/${milestoneId}/`);
      if (response.status) {
        SetAdminProjectData((prevData) => prevData.filter((milestone) => milestone.id !== milestoneId));
        toast.success('Project deleted successfully!', 'success');
      } else {
        toast.error('Error deleting Project', 'error');
      }
    } catch (error) {
      toast.error('Error deleting Project', 'error');
    }
  };

  // Handle edit button click
  const handleEditClick = (team) => {
    setEditedTeamID(team.id);
    setOldProjectName(team.projectname);
    setOldProjectMember(team.employee_select);
    setOldProjectStatus(team.projectstatus);
    updateModalContent(team);
    handleShowModal();
  };

  // setted values to null when closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setInputData({
      projectname: '',
      project_type: '',
      projectstatus: '',
      startdate: '',
      enddate: '',
      project_type: '',
      project_price: '',
      employee_select: [],
      reason_for_hold: '', // Add Field reason for Hold
    })
    setShowModal(false);
    setEditedTeamID(''); // Reset editedTeamID

  };

  const handleShowModal = () => {
    setShowModal(true);

  };

  // For adding values to the database
  const OnAddingProject = useCallback(async (e) => {
    try {
      // Attempt to make the API request
      const formattedStartDate = convertToYYYYMMDD(inputData.startdate);
      const formattedEndDate = convertToYYYYMMDD(inputData.enddate);
      const data = await postToAPI("project/", {
        projectname: inputData.projectname,
        projectdescription: inputData.projectdescription,
        projectstatus: inputData.projectstatus,
        startdate: formattedStartDate,
        enddate: formattedEndDate,
        project_type: inputData.project_type,
        project_price: inputData.project_price,
        reason_for_hold: inputData.reason_for_hold, //  Field Reason for hold
        employee_select: inputData.employee_select.map(employee => employee.value).join(','),

      });
      // Check if the API request was successful
      if (data.status) {
        SetAdminProjectData(data.data);
        toast.success("Admin project added successfully!", "success");
        handleCloseModal();
        //Notification
        const creation_time = new Date().toISOString();
        const type = "project";
        //Notification for project assigned
        inputData.employee_select.forEach(async (employee) => {
          const message = `You have been assigned a new project ${inputData.projectname} which starts from ${formattedStartDate} to ${formattedEndDate}`;
          await sendNotification(
            "notification/",
            type,
            message,
            creation_time,
            employee.value,
            false
          );
        });
        const addedMembers = inputData.employee_select;
        
        //Notification for project assigned to members
        inputData.employee_select.forEach(async (employee) => {
          if (addedMembers.length > 0) {
            const addedMembersLabels = addedMembers
              .map((member) => member.label)
              .join(", ");
            const message = `${addedMembersLabels} have been added to Team ${inputData.projectname}`;
            await sendNotification(
              "notification/",
              type,
              message,
              creation_time,
              employee.value,
              false
            );
          }
        });
        //Notification for project ends today or tomorrow
        inputData.employee_select.forEach(async (employee) => {
          if (inputData.enddate && inputData.enddate !== formattedEndDate) {
            const today = new Date();
            const endDate = new Date(inputData.enddate);
            const timeDifference = endDate.getTime() - today.getTime();
            const daysDifference = Math.ceil(
              timeDifference / (1000 * 60 * 60 * 24)
            );

            if (daysDifference === 1) {
              const message = `${inputData.projectname} will end tomorrow`;
              await sendNotification(
                "notification/",
                type,
                message,
                creation_time,
                employee.value,
                false
              );
            } else if (daysDifference === 0) {
              const message = `${inputData.projectname} will end today`;
              await sendNotification(
                "notification/",
                type,
                message,
                creation_time,
                employee.value,
                false
              );
            }
          }
        });
      } else {
        if (data.error && data.error.includes('duplicate')) {
          toast.error('Duplicate entry. Please provide a unique project name.', 'error');
        } else {
          showAlert('Some Error Occurred...', 'error');
        }
      }
      setEditedTeamID('');
    } catch (error) {
      console.error('Error adding Project data:', error);
      toast.warn('Error adding Project data', 'error');
    }
  }, [inputData]);


  // for updating values to database
  const updateDataInDatabase = useCallback(async (itemDetails) => {
    const updateUrl = `project/${editedTeamID}/`;
    if (!itemDetails.projectname || !itemDetails.projectdescription || !itemDetails.startdate || !itemDetails.enddate || !itemDetails.projectstatus || !itemDetails.project_price || !itemDetails.employee_select || !itemDetails.project_type) {
      toast.error('All Fields Are Requires', 'error')
    }
    const formattedStartDate = convertToYYYYMMDD(inputData.startdate);
    const formattedEndDate = convertToYYYYMMDD(inputData.enddate);

    const requestBody = {
      projectname: itemDetails.projectname || null,
      projectdescription: itemDetails.projectdescription || null,
      projectstatus: itemDetails.projectstatus || null,
      startdate: formattedStartDate || null,
      enddate: formattedEndDate || null,
      project_type: itemDetails.project_type || null,
      project_price: itemDetails.project_price || null,
      employee_select: itemDetails.employee_select ? itemDetails.employee_select.map(employee => employee.value).join(',') : null,
      reason_for_hold: itemDetails.reason_for_hold || null,
    };

    try {
      // Make a PUT request to update the data
      const data = await putToAPI(updateUrl, requestBody);
      if (data.message === "Item updated successfully") {
        SetAdminProjectData(data.data);
        toast.success('Admin Project updated successfully!', 'success');
        // Notification
        const loggedInUserId = localStorage.getItem('employee_id');
        const creation_time = new Date().toISOString();
        const type = "project";
        // Notification for project name
        itemDetails.employee_select.forEach(async (employee) => {
          // Notification for name change
          if (oldprojectname !== itemDetails.projectname) {
            const message = `The project name ${oldprojectname} has been updated to ${itemDetails.projectname}`;
            await sendNotification('notification/', type,message,creation_time,employee.value, false);
          }
        });
        // Notification for project status 
        itemDetails.employee_select.forEach(async (employee) => {
          // Notification for name change
          if (oldprojectstatus !== itemDetails.projectstatus) {
            const message = `The project name ${itemDetails.projectname} has updated its status to ${itemDetails.projectstatus}`;
            await sendNotification('notification/', type,message,creation_time,employee.value, false);
          }
        });
         
        //Notification for Start date change
        itemDetails.employee_select.forEach(async (employee) => {
          if (
            itemDetails.startdate &&
            itemDetails.startdate !== formattedStartDate
          ) {
            const message = `Start date of ${itemDetails.projectname} has been updated to ${formattedStartDate}`;
            await sendNotification('notification/', type,message,creation_time,employee.value, false);
          }
        });
        //Notification for End date change
        itemDetails.employee_select.forEach(async (employee) => {
          if (itemDetails.enddate && itemDetails.enddate !== formattedEndDate) {
            const message = `End date of ${itemDetails.projectname} has been updated to ${formattedEndDate}`;
            await sendNotification('notification/', 
              type,
              message,
              creation_time,
              employee.value, false
            );
          }
        });
        //Notification for project ends today or tomorrow
        itemDetails.employee_select.forEach(async (employee) => {
          if (itemDetails.enddate && itemDetails.enddate !== formattedEndDate) {
            const today = new Date();
            const endDate = new Date(itemDetails.enddate);
            const timeDifference = endDate.getTime() - today.getTime();
            const daysDifference = Math.ceil(
              timeDifference / (1000 * 60 * 60 * 24)
            );

            if (daysDifference === 1) {
              const message = `${itemDetails.projectname} will end tomorrow`;
              await sendNotification('notification/', 
                type,
                message,
                creation_time,
                employee.value, false
              );
            } else if (daysDifference === 0) {
              const message = `${itemDetails.projectname} will end today`;
              await sendNotification('notification/', 
                type,
                message,
                creation_time,
                employee.value, false
              );
            }
          }
        });

        // Find newly added members
        const oldMembers = oldprojectmember
        .split(",")
        .map((member) => member.trim());
      const newMembers = itemDetails.employee_select.map((employee) =>
        String(employee.value, false)
      );
      const newlyAddedMembers = newMembers.filter(
        (member) => !oldMembers.includes(member)
      );

      // Find removed members
      const removedMembers = [];
      oldMembers.forEach((member) => {
        if (!newMembers.includes(String(member))) {
          removedMembers.push(member);
        }
      });
      // Check if both newly added members and removed members exist
      if (newlyAddedMembers.length > 0 && removedMembers.length > 0) {
        // Generate names of newly added members
        const newlyAddedMembersNames = newlyAddedMembers.map(employeeId => {
            const employee = employeeListData.find(emp => emp.value.toString() === employeeId);
            return employee ? `${employee.first_name} ${employee.last_name}` : '';
        }).join(', ');

        // Generate names of newly removed members
        const newlyRemovedMembersNames = removedMembers.map(employeeId => {
            const employee = employeeListData.find(emp => emp.value.toString() === employeeId);
            return employee ? `${employee.first_name} ${employee.last_name}` : '';
        }).join(', ');

        // Generate combined notification message
        const message = `${newlyAddedMembersNames} have been added & ${newlyRemovedMembersNames} have been removed from project ${itemDetails.projectname}`;

        // Send combined notification to newMembers
        newMembers.forEach(async (employeeId) => {
            await sendNotification('notification/', type, message, creation_time, employeeId, false);
        });
      } else {
        // Check if there are newly added members
        if (newlyAddedMembers.length > 0) {
            // Generate names of newly added members
            const newlyAddedMembersNames = newlyAddedMembers.map(employeeId => {
                const employee = employeeListData.find(emp => emp.value.toString() === employeeId);
                return employee ? `${employee.first_name} ${employee.last_name}` : '';
            }).join(', ');

            // Notification for old members with newly added member's names
            oldMembers.forEach(async (employeeId) => {
                const message = `${newlyAddedMembersNames} have been added to project ${itemDetails.projectname}`;
                await sendNotification('notification/', type, message, creation_time, employeeId, false);
            });

            // Notification for newly added members
            newlyAddedMembers.forEach(async (employeeId) => {
                const message = `You have been added to project ${itemDetails.projectname}`;
                await sendNotification('notification/', type, message, creation_time, employeeId, false);
            });
        }

        // Check if users have been removed
        if (removedMembers.length > 0) {
            // Generate names of newly removed members
            const newlyRemovedMembersNames = removedMembers.map(employeeId => {
                const employee = employeeListData.find(emp => emp.value.toString() === employeeId);
                return employee ? `${employee.first_name} ${employee.last_name}` : '';
            }).join(', ');

            // Notification for other members with removed member's names
            newMembers.forEach(async (employeeId) => {
                const message = `${newlyRemovedMembersNames} have been removed from project ${itemDetails.projectname}`;
                await sendNotification('notification/', type, message, creation_time, employeeId, false);
            });

            // Notification for removed users
            removedMembers.forEach(async (employeeId) => {
                const employee = employeeListData.find(emp => emp.value === parseInt(employeeId, 10));
                if (employee) {
                    const message = `You have been removed from project ${itemDetails.projectname}`;
                    await sendNotification('notification/', type, message, creation_time, employee.value, false);
                }
            });
        }
      }
      } else {
        console.error('Unexpected response:', data.message);
      }
    } catch (error) {
      // Handle network or other errors
      console.error('Error updating Project:', error);
      toast.warn('Error updating Project', 'error');
    }
    setEditedTeamID('')
  }, [editedTeamID, inputData, employeeListData]);

  // function for the data setup and updates the state inputData
  const updateModalContent = useCallback((data) => {
    const preselectedEmployees = (data.employee_select || '').split(',').filter(id => id.trim() !== '').map(employeeId => {
      const parseId = parseInt(employeeId, 10);
      const employee = employeeListData.find(emp => emp.value === parseId);
      if (employee) {
        return { value: parseId, label: employee.label };
      }
      return null;
    }).filter(Boolean);

    setInputData({
      projectname: data.projectname,
      projectdescription: data.projectdescription,
      projectstatus: data.projectstatus,
      startdate: data.startdate,
      enddate: data.enddate,
      project_type: data.project_type,
      project_price: data.project_price,
      employee_select: preselectedEmployees, // Set all preselected employees
      reason_for_hold: data.reason_for_hold  // Field reason for hold
    });
  }, [employeeListData]);

  // Handle multi-select changes
  const handleSelectChange = (selectedOptions) => {
    setInputData((inputData) => ({
      ...inputData,
      employee_select: selectedOptions,
    }));
  };

  // Function to handle project deletion
  const handleDelete = async (itemId) => {
    try {
      // Show confirmation dialog before deleting
      showAlert(
        "Are you sure you want to delete this Admin Project?",
        "confirm",
        async (isConfirmed) => {
          if (!isConfirmed) {
            return; // If not confirmed, do nothing
          }

          try {
            const data = await deleteFromAPI(`project/${itemId}/`);

            if (data.message === "Item deleted successfully") {
              SetAdminProjectData(data.data); // Use the original function name
              toast.success('Admin Project deleted successfully!', 'success');

            } else {
              console.error("Unexpected response:", data.message);
            }
          } catch (error) {
            console.error("Error deleting project:", error);
          }
        }
      );
    } catch (error) {
      console.error("Error showing delete confirmation:", error);
    }
  };

  // Function to handle both adding and editing actions in the modal
  const handleBothActions = () => {
    if (!inputData.projectname || !inputData.projectdescription || !inputData.project_price || !inputData.startdate || !inputData.enddate || inputData.employee_select.length === 0 || !inputData.projectstatus || !inputData.project_type) {
      toast.error('All fields are required', 'error');
      return;
    }

    const isDuplicate = AdminProjectData.some((AdminProject) => (AdminProject.projectname === inputData.projectname) && (AdminProject.id !== editedTeamID));
    if (isDuplicate) {
      toast.warn('Project already exists. Please choose a different Project name.', 'error');
      return;
    }

    if ((inputData.projectstatus === 'On Hold' || inputData.projectstatus === 'Project Drop') && !inputData.reason_for_hold) {
      if (inputData.projectstatus === 'On Hold') {
        toast.error("Reason for Hold is required", 'error')
      }
      if (inputData.projectstatus === 'Project Drop') {
        toast.error("Reason for Project Drop is required", 'error')
      }

      return;
    }
    if (inputData.project_price < 0) {
      toast.warn("Project Price Should Be Greater Than 0", 'error');
      return
    }
    if (isNaN(inputData.project_price) || inputData.project_price.trim() === "") {
      toast.warn("Project Price Should Be In Number", 'error');
      return
    }
    if (convertToYYYYMMDD(inputData.startdate) > convertToYYYYMMDD(inputData.enddate)) {
      toast.warn("End Date Should be greater than Start date", 'error')
      return;
    }
    if (!inputData.projectname || inputData.projectname.trim() === "") {
      toast.warn("Project Name is required!", 'error')
      return;
    }

    if (editedTeamID === '') {
      OnAddingProject()
      handleCloseModal();
    } else {
      // For editing an existing team
      if (!inputData.projectname || !inputData.projectdescription || !inputData.project_price || !inputData.startdate || !inputData.enddate || inputData.employee_select.length === 0 || !inputData.projectstatus || !inputData.project_type) {
        toast.error('All fields are required', 'error');
      }

      if ((inputData.projectstatus === 'On Hold' || inputData.projectstatus === 'Project Drop') && !inputData.reason_for_hold) {
        if (inputData.projectstatus === 'On Hold') {
          toast.error("Reason for Hold is required", 'error')
        }
        if (inputData.projectstatus === 'Project Drop') {
          toast.error("Reason for Project Drop is required", 'error')
        }
      }

      if (inputData.project_price < 0) {
        toast.warn("Project Price Should Be Greater Than 0", 'error');
        return
      }
      if (inputData.startdate > inputData.enddate) {
        toast.warn("End Date Should be greater than Start date", 'error')
      }
      if (!inputData.projectname || inputData.projectname.trim() === "") {
        toast.warn("Project Name is required!", 'error')
        return;
      }
      if (isNaN(inputData.project_price) || inputData.project_price.trim() === "") {
        toast.warn("Project Price Should in Number", 'error');
        return
      }
      else {
        updateDataInDatabase(inputData);
        handleCloseModal();
      }

    }
  };

  // Function to format the date based on the selected format
  const formatDate = (dateString) => {
    // If the dateString is empty or null, return an empty string
    if (!dateString) return '';

    // Create a new Date object with the parts in 'MM-DD-YYYY' format
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.error(`Invalid date: ${dateString}`);
      return ''; // Return an empty string if the date is invalid
    }

    // Get the date components
    const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero if necessary
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Pad with leading zero if necessary
    const year = date.getFullYear();

    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }; // Define the default format

    // Custom formats based on the selected format
    switch (selectedFormat) {
      case 'YYYY-MM-DD':
        // Use custom format for 'YYYY-MM-DD'
        return year + "-" + month + "-" + day;
      case 'DD-MM-YYYY':
        // Use custom format for 'DD-MM-YYYY'
        return day + "-" + month + "-" + year;
      case 'MM-DD-YYYY':
        // Use custom format for 'MM-DD-YYYY'
        return month + "-" + day + "-" + year;
      // Add more cases for additional formats as needed
      default:
        // Default to the standard format
        return date.toLocaleDateString('en-GB', options);
    }
  };

  //function for the handling change date format    
  const handleChangeDate = (date, fieldName) => {
    setInputData((prevData) => ({
      ...prevData,
      [fieldName]: date, // Update the state with the selected date
    }));
  };

  // For handling Edit action
  const HandleEditActions = (team) => {
    setEditedTeamID(team.id);
    updateModalContent(team)
    handleShowModal();
  };

  //function for the end date condition 
  const isPastDue = (endDate) => {
    const currentDate = new Date();
    const projectEndDate = new Date(endDate);
    return currentDate > projectEndDate;
  };

  // function for the calulting the days 
  const szcalculateDuration = (start, end) => {
    const formattedStartDate = convertToYYYYMMDD(start);
    const formattedEndDate = convertToYYYYMMDD(end);
    const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const startDate = new Date(formattedStartDate);
    const endDate = new Date(formattedEndDate);
    const daysDifference = Math.round(Math.abs((startDate - endDate) / oneDay));
    return isNaN(daysDifference) ? '' : daysDifference; // Check if daysDifference is NaN and return an empty string if so
  };

  // function for the handling data change
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "projectstatus" && (value !== "On Hold" || value !== "Project Drop")) {
      setInputData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setInputData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Function to convert any date format to yyyy-mm-dd format
  const convertToYYYYMMDD = (dateString) => {
    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Check if the input string is a valid date
    if (isNaN(date.getTime())) {
      console.error(`Invalid date: ${dateString}`);
      return ''; // Return an empty string if the date is invalid
    }

    // Get the components of the date (year, month, day)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if necessary
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if necessary

    // Return the date in yyyy-mm-dd format
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <PageTitle breadcrumbLast=" Admin Project" />
          <Container>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Card.Title className="mb-lg-0">Projects List</Card.Title>
                      <div className="d-md-flex">
                        <Button
                          variant="soft-success mb-1 mb-md-0" className="btn-icon"
                          onClick={handleShowModal}
                        >
                          <i className="bi bi-plus"></i>
                        </Button>
                      </div>
                    </div>
                    {/* End of card title */}
                    <Table
                      responsive
                      bordered
                      className="mb-0 table-nowrap w-100 maindatatable"
                      size="sm"
                    >
                      <thead className="table-light">
                        <tr>
                          <th>Project Name</th>
                          <th>Work Timeline</th>
                          <th>Total Days</th>
                          <th>Project Types</th>
                          <th>Projects Team</th>
                          <th>Status</th>
                          <th>Progress</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
      <Modal
        centered
        show={showModal} onHide={handleCloseModal}
        dialogClassName="modal-lg"
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title> {editedTeamID ? 'Edit Project' : 'Add Project'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>
                <Form>
                  <Col md={12}>
                    <Form.Group className="custom-fr-group">
                      <Form.Label>Project Name </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter project name..."
                        required
                        name="projectname"
                        id="projectname"
                        value={inputData.projectname}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="custom-fr-group">
                      <Form.Label>Project Description</Form.Label>
                      <Form.Control
                        placeholder="Enter description..."
                        as="textarea"
                        rows={4}
                        name="projectdescription"
                        id="projectdescription"
                        value={inputData.projectdescription}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Row>
                    <Col lg={12} sm={12}>
                      <Form.Group className="custom-fr-group">
                        <Form.Label>Project Status</Form.Label>
                        <Form.Select
                          name="projectstatus"
                          id="projectstatus"
                          value={inputData.projectstatus}
                          onChange={handleChange}
                        >
                          <option value="">Select Project Status</option>
                          {Project_status.map((status) => (
                            <option key={status.project_status_name}>
                              {status.project_status_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    {(inputData.projectstatus === "On Hold" || inputData.projectstatus === "Project Drop") && (
                      <Form.Group>
                        <Form.Label>{inputData.projectstatus === "On Hold" ? "Reason For Hold" : "Reason For Project Drop"}</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="reason_for_hold"
                          placeholder={inputData.projectstatus === "On Hold" ? "Enter Reason for Hold" : "Enter Reason for project Drop"}
                          value={inputData.reason_for_hold}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    )}
                  </Row>
                  <Row>
                    <Col lg={4} sm={12}>
                      <Form.Group className="custom-fr-group">
                        <Form.Label>Start Date</Form.Label>
                        <div className="input-icon-content custom-form-input">
                        <i className={`bi bi-calendar input-icon`} onClick={() => document.getElementById('start_Date').click()}></i>
                        </div>
                        <DatePicker
                          id = "start_Date"
                          selected={inputData.startdate ? new Date(inputData.startdate) : null}
                          onChange={(date) => handleChangeDate(date, "startdate")}
                          placeholderText="Select start date"
                          dateFormat={formatDate(inputData.startdate)}
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={100}
                          autoComplete="off"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={4} sm={12}>
                      <Form.Group>
                        <Form.Label>End Date</Form.Label>
                        <div className="input-icon-content">
                        <i className={`bi bi-calendar input-icon`} onClick={() => document.getElementById('end_Date').click()}></i>
                        </div>
                        <DatePicker
                          id = 'end_Date'
                          selected={inputData.enddate ? new Date(inputData.enddate) : null}
                          onChange={(date) => handleChangeDate(date, "enddate")}
                          placeholderText="Select end date"
                          dateFormat={formatDate(inputData.enddate)}
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={100}
                          autoComplete="off"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={4} sm={12}>
                      <Form.Group>
                        <Form.Label>Total Days</Form.Label>
                        <Form.Control
                          type="days"
                          name="days"
                          id="days"
                          placeholder="Total Days"
                          value={szcalculateDuration(convertToYYYYMMDD((inputData.startdate)), convertToYYYYMMDD((inputData.enddate)))}
                          onChange={handleChange}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} sm={12}>
                      <Form.Group className="custom-fr-group">
                        <Form.Label>Project Type</Form.Label>
                        <Form.Select
                          name="project_type"
                          id="project_type"
                          value={inputData.project_type}
                          onChange={handleChange}
                        >
                          <option value="">Select Project type</option>
                          <option>Recurring</option>
                          <option>Fixed</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={6} sm={12}>
                      <Form.Group className="custom-fr-group">
                        <Form.Label>Project Price </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter project price..."
                          required
                          name="project_price"
                          id="project_price"
                          value={inputData.project_price}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Col md={12}>
                    <Form.Group className="custom-form-input">
                      <Form.Label>Member Assign</Form.Label>
                      <Select
                        options={employeeListData}
                        placeholder="Select employees..."
                        name="employee_select"
                        onChange={handleSelectChange}
                        isMulti={true} // Ensure isMulti is set to true
                        value={inputData.employee_select}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                      />
                    </Form.Group>
                  </Col>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="">
          <Button variant="primary" onClick={handleBothActions}>
            Ok
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
    </>
  );
};

export default AdminProject;