import React, { useState, useEffect,useCallback } from "react";
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import { Button, Container, Card, Row, Col, Modal, Form } from "react-bootstrap";
import { getFromAPI, postToAPI , putToAPI } from '../Utils/utils.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDateFormat } from "../../context/DateFormatContext.js";
import { useNavigate } from "react-router-dom";
export default function Setting() {
  const { selectedFormat, updateSelectedFormat } = useDateFormat();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [originalFormat, setOriginalFormat] = useState(selectedFormat);
  useEffect(() => {
    document.title = 'General Setting | PMRS App';
    return () => {
      document.title = 'PMRS App';
    };
  }, []);
  useEffect(() => {
    const User_Id = localStorage.getItem('employee_id');
    const isPositionAdmin = localStorage.getItem('Position');
    if (User_Id === null || isPositionAdmin !== 'Admin') {
      navigate('/');
    }
  });
  const [inputData, setinputData] = useState({
    casualLeave: '0',
    paidLeave: '0',
    sickLeave: '0',
    annualLeave: '0',
    setting_name : '0',
  });
  // State for controlling the target modal
const [showLeaveModal, setshowLeaveModal] = useState(false);
const handleCloseLeaveModal = () => setshowLeaveModal(false);
const handleshowLeaveModal = () => setshowLeaveModal(true);
// Function to check if any value in an array is negative
function hasNegativeValue(array) {
  return array.some(value => value < 0);
}
  const OnAddingLeave = useCallback(async () => {
    try {
      const data = await getFromAPI(`setting/`)
      if (data.length === 0) {
        const totalLeave = parseInt(inputData.casualLeave ? inputData.casualLeave : 0) + parseInt(inputData.sickLeave ? inputData.sickLeave : 0) + parseInt(inputData.paidLeave ? inputData.paidLeave : 0)
        const response =await postToAPI('setting/',{ 
          casualLeave: inputData.casualLeave,
        paidLeave: inputData.paidLeave,
        sickLeave: inputData.sickLeave,
        annualLeave: totalLeave.toString(),
        })
        if (response) {
          // Handle success scenario, if needed
          toast.success("Settings saved successfully");
        } else{
          // Handle failure scenario, if needed
          toast.error("Failed to save settings. Please try again later.");
        }
      }else{
      const totalLeave = parseInt(inputData.casualLeave ? inputData.casualLeave : 0) + parseInt(inputData.sickLeave ? inputData.sickLeave : 0) + parseInt(inputData.paidLeave ? inputData.paidLeave : 0)
      const data = await getFromAPI(`setting/`)
      const Id = data[0].id;
      const response = await putToAPI (`setting/${Id}/`, {
        casualLeave: inputData.casualLeave,
        paidLeave: inputData.paidLeave,
        sickLeave: inputData.sickLeave,
        annualLeave: totalLeave.toString(),
      });
      if (response) {
        // Handle success scenario, if needed
        toast.success("Settings saved successfully");
      } else{
        // Handle failure scenario, if needed
        toast.error("Failed to save settings. Please try again later.");
      }}
    } catch (error) {
      // Handle errors
      console.error("Error adding settings:", error);
      toast.error("Error adding settings: " + error.message);
    }
  }, [inputData]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Remove leading '0's from the input value
    const sanitizedValue = value.replace(/^0+/, '');
    // Prevent negative values
    if (sanitizedValue < 0) {
      return; // Do nothing if the value is negative
    }
    // Update inputData state with the sanitized value
    setinputData({ ...inputData, [name]: sanitizedValue });
  };
  
  function UpdateSettingData(itemDetails) {
    var no_casualLeave = itemDetails.casualLeave;
    var no_paidLeave = itemDetails.paidLeave;
    var no_sickLeave = itemDetails.sickLeave;
    var no_annualLeave = itemDetails.annualLeave;
  
    const updatedLeavenumbers = [
     {value: no_casualLeave || 0},
     {value: no_paidLeave || 0},
     {value: no_sickLeave || 0},
     {value: no_annualLeave || 0},
  
    ];
    setinputData(updatedLeavenumbers);
  }
  const fetchData = async () => {
    try {
      
        const fetchSettingData = async () => {
            try {
                const data = await getFromAPI(`setting/`)
                const Id = data[0].id;
                const response = await getFromAPI(`setting/${Id}/`);
                if (!response || response.length === 0) {
                    throw new Error('Network response was not ok or response is empty');
                }
                // Set user leave numbers
                UpdateSettingData(JSON.parse(response.data)[0].fields);
                setinputData(JSON.parse(response.data)[0].fields);
  
            } catch (error) {
               
            }
        };
  
        fetchSettingData();
    } catch (error) {
        toast.error('Error fetching data:' + error.message, "Error");
    }
  };
  
  // Effect to fetch employee data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);
   // Function to save leave when click on save Button 
    const handleSaveActionsLeave = useCallback(async() => {
      if (!inputData.casualLeave.trim()||!inputData.paidLeave.trim()||!inputData.sickLeave.trim())
      {
        handleCloseLeaveModal();
        toast.error('Field values can not be null.');
        return;
      }
      if(inputData.annualLeave >50 || inputData.sickLeave >50 || inputData.paidLeave >50 || inputData.casualLeave>50)
      {
        handleCloseLeaveModal();
        toast.error('Cannot be Grater than 50.');
        return;
      }
      if(hasNegativeValue(Object.values(inputData)))
      {
        handleshowLeaveModal();
        toast.error('Cannot be Negative.');
        return;      
      }
      await OnAddingLeave();
      setShowModal1(false);
    },[inputData,handleshowLeaveModal,OnAddingLeave,handleCloseLeaveModal])
  
  const saveDateFormat = async () => {
    try {
      const Existing_setting =await getFromAPI('setting/');
      if (Existing_setting.length === 0) {
        await postToAPI('setting/',{setting_name : selectedFormat});
        setShowModal(false);
        toast.success('Date format saved successfully!');
      } else {
        const Existing_id = Existing_setting[0].id;
        await putToAPI(`setting/${Existing_id}/`, { setting_name: selectedFormat });
        toast.success('Date format saved successfully!');
        setShowModal(false);
        setOriginalFormat(selectedFormat); // Update original format after saving
      }
    } catch (error) {
      toast.error('Failed to save date format. Please try again later.');
    }
  };
  const changeDropdown = () => {
    const selectElement = document.getElementById("exampleForm.ControlSelect1");
    if (selectElement) {
      const selectedOption = selectElement.options[selectElement.selectedIndex].value;
      updateSelectedFormat(selectedOption);
    }
  };
  const handleModalClose = () => {
    setShowModal(false);
    updateSelectedFormat(originalFormat); // Reset to original format on modal close
  };
  const handleCloseModal = () => {
    setShowModal1(false)
    fetchData();
  }
    
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <PageTitle breadcrumbLast="General Setting" />
          <Container>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <div className="d-md-flex justify-content-between align-items-center mb-3">
                      <Card.Title className="mb-md-0">Date Format Setting</Card.Title>
                      <div>
                        {/* Button to trigger modal */}
                        <Button onClick={() => setShowModal(true)}>Change Date Format</Button>
                      </div>
                    </div>
                    <div className="d-md-flex justify-content-between align-items-center mb-3">
                      <Card.Title className="mb-md-0">Leave Setting</Card.Title>
                      <div>
                        {/* Button to trigger modal */}
                        <Button onClick={() => setShowModal1(true)}>Set Leaves for employee</Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
      {/* Modal for selecting date format */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Date Format</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group className="mb-3">
            <Form.Label>Select Format</Form.Label>
            <div className="input-icon-content">
              <i
                className={`bi bi-chevron-up input-icon`}
                onClick={changeDropdown}
              ></i>
            </div>
            <Form.Control as="select" value={selectedFormat} onChange={(e) => updateSelectedFormat(e.target.value)}>
              <option value="YYYY-MM-DD">YYYY-MM-DD</option>
              <option value="DD-MM-YYYY">DD-MM-YYYY</option>
              <option value="MM-DD-YYYY">MM-DD-YYYY</option>
              {/* Add more date formats as needed */}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={saveDateFormat}>Save</Button>
          <Button variant="secondary" onClick={handleModalClose}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      {/* Modal for genral leave adding for employees*/}
      <Modal show={showModal1} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Set leaves</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Row className="mb-3 align-items-center">
              <Col md={5}>
                Set Casual Leave
              </Col>
              <Col md={7}>
                <Form.Control
                  type="number"
                  placeholder="Enter Casual Leave"
                  name="casualLeave"
                  value={inputData.casualLeave ? inputData.casualLeave : 0}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="mb-3 align-items-center">
              <Col md={5}>
                Set Paid Leave
              </Col>
              <Col md={7}>
                <Form.Control
                  type="number"
                  placeholder="Enter Paid Leave"
                  name="paidLeave"
                  value={inputData.paidLeave ? inputData.paidLeave : 0}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="mb-3 align-items-center">
              <Col md={5}>
                Set Sick Leave
              </Col>
              <Col md={7}>
                <Form.Control
                  type="number"
                  placeholder="Enter Sick Leave"
                  name="sickLeave"
                  value={inputData.sickLeave ? inputData.sickLeave : 0}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="mb-3 align-items-center">
              <Col md={5}>
                Set Annual Leave
              </Col>
              <Col md={7}>
                <Form.Control
                  type="number"
                  placeholder="Enter Annual Leave"
                  name="annualLeave"
                  value={parseInt(inputData.casualLeave ? inputData.casualLeave : 0) + parseInt(inputData.sickLeave ? inputData.sickLeave : 0) + parseInt(inputData.paidLeave ? inputData.paidLeave : 0)}
                  readOnly
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSaveActionsLeave}>Save</Button>
          <Button variant="secondary" onClick={handleCloseModal}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored"/> {/* Toast notification container */}
    </>
  );
}
